<template>
    <v-container v-if="loading">
      <div class="text-center">
        <v-progress-circular
          indeterminate
          :size="80"
          :width="8"
          color="primary">
        </v-progress-circular>
      </div>
    </v-container>
    <v-container fluid v-else>
      <v-row class="justify-center">
        <v-col cols="12">
          <v-card class="mx-0 pa-5" outlined>
            <v-form @submit.prevent="submit">
              <v-row>
                <v-col cols="12">
                  <v-text-field
                    outlined
                    v-model="geoContent.URL"
                    label="URL"
                    :disabled="geoContentUrl != ''"
                  ></v-text-field>

                  <v-text-field
                    outlined
                    v-model="geoContent.MetaTitle"
                    label="Meta Title"
                  ></v-text-field>

                  <v-textarea
                    outlined
                    v-model="geoContent.MetaDescription"
                    label="Meta Description"
                    height="200"
                  ></v-textarea>

                  <v-textarea
                    outlined
                    v-model="geoContent.Th"
                    label="Content (TH)"
                    height="200"
                  ></v-textarea>

                  <v-textarea
                    outlined
                    v-model="geoContent.En"
                    label="Content (EN)"
                    height="200"
                  ></v-textarea>
                </v-col>
              </v-row>
  
              <v-divider></v-divider>  
              <br />
              <v-row>
                <v-spacer></v-spacer>
                <v-btn class="mr-4" @click="cancel">
                  Cancel
                </v-btn>
  
                <v-btn color="primary" type="submit" v-if="userCode != '9a0064aa-8c44-4597-b60f-7dc8dcb8c532'">
                  Confirm
                </v-btn>
              </v-row>
            </v-form>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
</template>
  
<script>
import geoService from "@/services/geoService";
  
export default {
    name: "geocontent-edit",
    // components:{
    // },
    data: () => ({
      loading: false,
      geoContentUrl: "",
      geoContent: {
        URL: "",
        MetaTitle: "",
        MetaDescription: "",
        Th: "",
        En: ""
      },
    }),
    async mounted() {
      try {
        this.loading = true;
        this.geoContentUrl = this.$route.params.id ?? "";
        if (this.geoContentUrl != "")
        {
          let result = await geoService.getItem(this.geoContentUrl, "", "");
          if (result.Data != null)
            this.geoContent = result.Data;
        }
      } catch (error) {
        alert(error)
      }
      finally {
        this.loading = false;
      } 
    },
  
    methods: {
      cancel() {
        this.$router.back();
      },
      async submit() {
        try {
          this.loading = true;
          let result;
          if (this.geoContentUrl == "") {
            result = await geoService.createItem(this.geoContent.URL, this.geoContent.MetaTitle, this.geoContent.MetaDescription, this.geoContent.Th, this.geoContent.En);
          }
          else {
            result = await geoService.updateItem(this.geoContentUrl, this.geoContent.MetaTitle, this.geoContent.MetaDescription, this.geoContent.Th, this.geoContent.En);
          }
          // if (result.Status == "success")
          //     alert('Save Success.')
          this.$router.back();
        } catch (error) {
          alert(error);
        }
        finally {
          this.loading = false;
        }
      },
    },
  computed: {
    userCode() {
      return this.$store.getters["userInfo"]["userCode"]?.toString() ?? "";
    },
  }
  };
</script>
  
<!-- <style scoped>
</style> -->