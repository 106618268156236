import axios from "axios";
import store from "@/store";
import { server, apiUrl2 } from "@/services/constants";
// import httpClient from "@/services/httpClient";

function getExpireTime(sec) {
    const t = new Date();
    if (sec > 0)
        t.setSeconds(t.getSeconds() + sec);
    return parseInt(t / 1000); //utc datetime
}

// ย้ายเสร็จ เปลี่ยนไปใช้ httpClient
async function refresh_token() {
    let userCode = localStorage.getItem(server.USERNAME) ?? "";
    let refreshToken = localStorage.getItem(server.REFRESH_TOKEN) ?? "";
    const instance = await getInstanceWithOutToken(apiUrl2);
    let payload = {
        "userCode": userCode,
        "refreshToken": refreshToken
    };
    return (
        await instance.post("/refresh_token", payload)
    ).data;
}

// async function refresh_token() {
//     let userCode = localStorage.getItem(server.USERNAME) ?? "";
//     let refreshToken = localStorage.getItem(server.REFRESH_TOKEN) ?? "";
//     let payload = {
//         "userCode": userCode,
//         "refreshToken": refreshToken
//     };
//     return (
//         await httpClient.post("/refresh_token", payload)
//     ).data;
// }

async function getToken() {
    var d1 = localStorage.getItem(server.EXPIRES_TIME) ?? 0;
    if (d1 == null) d1 = 0;
    if (d1 > 0) {
        var d2 = getExpireTime(0);
        if (d2 > d1) {
            try {
                let response = await refresh_token();
                // console.log("--- refreshToken ---", d1, d2, response);
                localStorage.setItem(server.ACCESS_TOKEN, response.accessToken);
                localStorage.setItem(server.ID_TOKEN, response.idToken);
                localStorage.setItem(server.EXPIRES_IN, response.expiresIn);
                localStorage.setItem(server.EXPIRES_TIME, getExpireTime(response.expiresIn - 120));
                return response.idToken;
            } catch (error) {
                store.dispatch("doLogout");
            }
        }
        
        let token = localStorage.getItem(server.ID_TOKEN) ?? "";
        if (token==null || token.toString().trim()=="")
            store.dispatch("doLogout")
        else 
            return token;
    }
    else
    {
        store.dispatch("doLogout")
        return "";
    }

    // if (d1 > 0) //ให้ของเก่าใช้ได้ก่อน
    // {
    //     let token = localStorage.getItem(server.ID_TOKEN) ?? "";
    //     if (token == null || token.toString().trim() == "")
    //         store.dispatch("doLogout");

    //     else
    //         return token;
    // }
    // return "";

    // let token = localStorage.getItem(server.ID_TOKEN) ?? "";
    // if (token==null || token.toString().trim()=="")
    //     store.dispatch("doLogout")
    // else 
    //     return token;
}

async function getInstance(api_url) {
    const token = await getToken();
    const instance = axios.create({
        baseURL: `${api_url}`,
        headers: {
            'Access-Control-Allow-Origin': `*`,
            // 'Authorization': `Bearer ${token}`, 
            'Authorization': `${token}`,
            'Content-Type': `application/json`
        },
        timeout: 0 // 600000
    });
    return instance;
}

async function getInstanceWithOutToken(api_url) {
    const instance = axios.create({
        baseURL: `${api_url}`,
        headers: {
            'Access-Control-Allow-Origin': `*`,
            'Content-Type': `application/json`
        },
        timeout: 0 //600000
    });
    return instance;
}

// ย้ายเสร็จ เปลี่ยนไปใช้ httpClient
async function sign_in(username, password) {
    const instance = await getInstanceWithOutToken(apiUrl2);
    let payload = {
        "username": username,
        "password": password
    };
    return (
        await instance.post("/sign_in", payload)
        // await httpClient.post("/sign_in", payload)
    ).data;
}

// ย้ายเสร็จ เปลี่ยนไปใช้ httpClient
async function change_password(username, old_password, new_password) {
    const instance = await getInstanceWithOutToken(apiUrl2);
    let payload = {
        "username": username,
        "old_password": old_password,
        "new_password": new_password
    };
    return (
        await instance.post("/change_password", payload)
        // await httpClient.post("/change_password", payload)
    ).data;
}

// ย้ายเสร็จ เปลี่ยนไปใช้ httpClient
// async function getUserInfo(userCode) {
//     // const instance = await getInstance(apiUrl2);
//     let payload = {
//         "userCode": userCode
//     };
//     return (
//         await httpClient.post("/get_user", payload)
//         // await instance.post("/get_user", payload)
//     ); //.data;
// }

// async function getUserByName(data) {
//     // const instance = await getInstance(apiUrl2);
//     // let payload = {
//     //     "userName": userName
//     // };
//     return (
//         // await instance.post("/get_user_by_name", payload)
//         await httpClient.post("/get_user_by_name", data)
//     ); //.data;
// }

// async function getUserList(data) {
//     // const instance = await getInstance(apiUrl2);
//     // let payload = {
//     //     "userCode": "", 
//     //     "userFirstName": "", 
//     //     "userGroup": "Operation", 
//     //     "active": 1
//     // };
//     return (
//         await httpClient.post("/get_user_list", data)
//         // await instance.post("/get_user_list", data)
//     ); //.data;
// }

// ย้ายเสร็จ เปลี่ยนไปใช้ httpClient
async function createUser(data) {
    const instance = await getInstance(apiUrl2);
    let payload = data;
    return (
        await instance.post("/createUser", payload)
    ).data;
}

// ย้ายเสร็จ เปลี่ยนไปใช้ httpClient
async function updateUser(data) {
    const instance = await getInstance(apiUrl2);
    let payload = data;
    return (
        await instance.post("/updateUser", payload)
    ).data;
}

export default {
    getExpireTime,
    // refresh_token,
    getToken,
    getInstance,
    getInstanceWithOutToken,
    sign_in,
    change_password,
    // getUserInfo,
    // getUserByName,
    // getUserList,
    createUser,
    updateUser
};