<template>
  <v-container v-if="loading">
    <div class="text-center">
      <v-progress-circular
        indeterminate
        :size="80"
        :width="8"
        color="primary">
      </v-progress-circular>
    </div>
  </v-container>
  <v-container fluid v-else>
    <v-row class="justify-center">
      <v-col cols="12">
        <v-card class="mx-10  pa-5" outlined>
          <v-form @submit.prevent="submit">
            <template>
              <v-row class="ma-0">
                <v-spacer></v-spacer>
                <h1>Import Product Attribute (Butcher)</h1>
                <v-spacer></v-spacer>
              </v-row>
            </template>

            <v-divider></v-divider>
            <br />
            <template>
              <v-row class="ma-0">
                <h3 class="mr-3">Import Excel (XLSX)</h3>
                <input type="file" accept=".xlsx" @change="onFileImportSelected" ref="importFile">
                <v-spacer></v-spacer>
                <v-btn color="primary" @click="clearData">
                  <v-icon left>delete</v-icon>
                  Clear All
                </v-btn>
              </v-row>
            </template>

            <br />
            <v-divider></v-divider>

            <template>
              <div class="row">
                <div class="col-12">
                  <table class="table table-striped" width="100%">
                    <thead class="thead-dark" align-center>
                      <tr>
                        <th scope="col" style="text-align: left">Image</th>
                        <th scope="col" style="text-align: left">(C) Pr Code</th>
                        <th scope="col" style="text-align: left">Pr Name</th>
                        <th scope="col" style="text-align: left">Online</th>
                        <th scope="col" style="text-align: left">Action</th>
                      </tr>
                    </thead>
                    <tbody>
                      <template v-for="item in mDataArray">
                        <tr>
                          <td>
                              <v-img
                                contain
                                class="ma-2"
                                :eager="true"
                                :src="item.image"
                                lazy-src="@/assets/noimage.png"
                                aspect-ratio="1"
                                min-width="80"
                                max-width="80"
                                max-height="80"
                              >
                                <template v-slot:placeholder>
                                  <v-row
                                    class="fill-height ma-0"
                                    align-center
                                    justify="center"
                                  >
                                    <v-progress-circular v-show="loading"
                                      indeterminate
                                      color="grey lighten-5"
                                    ></v-progress-circular>
                                  </v-row>
                                </template>
                              </v-img>
                          </td>
                          <td>{{ item.cprcode }}</td>
                          <td>{{ item.hema_name_en }}</td>
                          <td>
                            <v-checkbox
                              readonly
                              v-model="item.master_online"
                            ></v-checkbox>
                          </td>
                          <td>
                            <v-icon @click="removeItem(item)">
                              delete
                            </v-icon>
                          </td>
                        </tr>

                        <tr><td colspan="5" width="100%">
                          <h3>Product Attribute :</h3>
                          <div class="header">รายละเอียด ภาษาอังกฤษ</div>
                          <div><span style="color: #0D47A1;">Origin : </span>{{ item.product_attribute.origin }}</div>
                          <div><span style="color: #0D47A1;">Breed :  </span>{{ item.product_attribute.breed }}</div>
                          <div><span style="color: #0D47A1;">Feed :  </span>{{ item.product_attribute.feed }}</div>
                          <div><span style="color: #0D47A1;">Description :  </span>{{ item.product_attribute.description }}</div>
                          <div class="header">รายละเอียด ภาษาไทย</div>
                          <div><span style="color: #0D47A1;">Origin : </span>{{ item.product_attribute.origin_th }}</div>
                          <div><span style="color: #0D47A1;">Breed :  </span>{{ item.product_attribute.breed_th }}</div>
                          <div><span style="color: #0D47A1;">Feed :  </span>{{ item.product_attribute.feed_th }}</div>
                          <div><span style="color: #0D47A1;">Description :  </span>{{ item.product_attribute.description_th }}</div>
                        </td></tr>

                        <tr><td colspan="5" width="100%">
                        <v-divider color="#0D47A1"></v-divider>
                        </td></tr>

                      </template>
                    </tbody>
                  </table>
                </div>
              </div>
            </template>

            <!-- <br />
            <v-divider></v-divider> -->
            <br />
            <v-row>
              <v-spacer></v-spacer>
              <v-btn class="mr-4" @click="cancel">
                Cancel
              </v-btn>

              <v-btn color="primary" type="submit" v-if="userCode != '9a0064aa-8c44-4597-b60f-7dc8dcb8c532'">
                Confirm
              </v-btn>
            </v-row>

          </v-form>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import api from "@/services/lambdaApi";
import XLSX from 'xlsx';

export default {
  name: "import-product-attribute-butcher",
  // components:{
  // },
  data: () => ({
    loading: false,
    mDataArray: []
  }),
  async mounted() {
  },
  methods: {
    removeItem: function (item) {
      let mProduct = this.mDataArray.find(d => d.cprcode === item.cprcode);
      if (mProduct) {
        let index = this.mDataArray.indexOf(mProduct);
        if (index >= 0) {
          this.mDataArray.splice(index, 1)
        }
      }
    },
    addProductAttribute: function (prcodeList, item) {
      let mProduct = prcodeList.find(d => parseInt(d.prcode) === parseInt(item.cprcode));
      if (mProduct) {
        let product_attribute = {
          origin: mProduct["Origin"],
          breed: mProduct["Breed"],
          feed: mProduct["Feed"],
          description: mProduct["Description"],
          origin_th: mProduct["Origin_th"],
          breed_th: mProduct["Breed_th"],
          feed_th: mProduct["Feed_th"],
          description_th: mProduct["Description_th"]
        }
        // console.log('-- product_attribute --', product_attribute);
        item["product_attribute_id"] = 2;
        item["product_attribute"] = product_attribute;
      }
    },
    cancel() {
      this.$router.back();
    },
    clearData() {
      this.mDataArray = []
    },
    onFileImportSelected(e) {
      try {
        this.loading = true;
        var files = e.target.files || e.dataTransfer.files;
        if (files.length == 0)
          return;
        this.previewFiles(files[0]);
      // } catch (error) {
      }
      finally {
        this.$refs.importFile.value = "";
        setTimeout(() => {
          this.loading = false;
        }, 2000);
      }
    },
    previewFiles(file) { // detect file event
        var reader = new FileReader();
        var vm = this;
        reader.onload = async function(e) {
            var data = new Uint8Array(e.target.result);
            var workbook = XLSX.read(data, { type: "array" });
            let sheetName = workbook.SheetNames[0];
            /* DO SOMETHING WITH workbook HERE */
            // console.log(workbook);
            let worksheet = workbook.Sheets[sheetName];
            // console.log(XLSX.utils.sheet_to_json(worksheet));

            let prcodes = XLSX.utils.sheet_to_json(worksheet);
            // console.log('-- prcodes --', prcodes);
            let prcodeList = [];
            prcodes.forEach(item => {
              prcodeList.push(parseInt(item.prcode ?? 0));
            });
            if (prcodeList && prcodeList.length>0) {
              // console.log('-- prcodeList --', prcodeList);
              let result = await api.getProductByPrcodeList(prcodeList);
              let dataArray  = result.data;
              dataArray.forEach(item => {
                // console.log('-- item --', item);
                vm.addProductAttribute(prcodes, item);
              });
              vm.mDataArray = dataArray;
              // console.log('-- vm.mDataArray --', vm.mDataArray);
            }
        };
        reader.readAsArrayBuffer(file);
    },
    async submit() {
      try {
        this.loading = true;
        let result = await api.setProductAttribute(JSON.stringify(this.mDataArray));
        console.log('result',result);
        this.$router.back();
      // } catch (error) {
      }
      finally {
        this.loading = false;
      }
    },
  },
  computed: {
    userCode() {
      return this.$store.getters["userInfo"]["userCode"]?.toString() ?? "";
    },
  }
};
</script>

<style scoped>
table {
  border-spacing: 0 0.5rem;
}
tbody tr:nth-of-type(odd) {
   background-color: rgba(0, 0, 0, .05);
}
.header {
  font-size: 15px;
  font-weight: bold;
  color: #D50000;
}
</style>