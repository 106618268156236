// import { imageUrl } from "./constants";
import httpClient from "@/services/httpClient";
// import s3Client from "@/services/s3Client";
import { server } from "@/services/constants";
// import auth from "@/services/auth";

// Product
export const getProducts = (data) => {
  return httpClient.post(server.PRODUCT_URL, data);
};

export const getProductByCprcode = (data) => {
  return httpClient.post("getProductByCprcode", data);
};

export const setProductByCprcode = (data) => {
  return httpClient.post("setProductByCprcode", data);
};

export const refreshProduct = (data) => {
  return httpClient.post("refreshProduct", data);
};

export const getGroupList = (data) => {
  return httpClient.post("getGroupList", data);
};

export const getGroupById = (data) => {
  return httpClient.post("getGroupById", data);
};

export const createGroup = (data) => {
  return httpClient.post("createGroup", data);
};

export const updateGroup = (data) => {
  return httpClient.post("updateGroup", data);
};

export const refreshGroup = (data) => {
  return httpClient.post("refreshGroup", data);
};

export const getS3Json = (data) => {
  return httpClient.post("getS3Json", data);
};

// Category Level1

export const getCategoryLv1 = (data) => {
  return httpClient.post("getCategoryLv1", data);
};

export const addCategoryLv1 = (data) => {
  return httpClient.post("addCategoryLv1", data);
};

export const removeCategoryLv1 = (data) => {
  return httpClient.post("removeCategoryLv1", data);
};

export const getBanner = (data) => {
  return httpClient.post("getBanner", data);
};

export const newBanner = (data) => {
  return httpClient.post("newBanner", data);
};

export const updateBanner = (data) => {
  return httpClient.post("updateBanner", data);
};

export const getBannerById = (data) => {
  return httpClient.post("getBannerById", data);
};

// ----
// export const getProductById = (id) => {
//   return httpClient.get(server.PRODUCT_URL + `/id/${id}`);
// };

// export const addProduct = (data) => {
//   return httpClient.post(server.PRODUCT_URL, data);
// };

// export const deleteProduct = (id) => {
//   return httpClient.delete(server.PRODUCT_URL + `/id/${id}`);
// };

// export const updateProduct = (data) => {
//   return httpClient.put(server.PRODUCT_URL, data);
// };

export const presignUrls = (data) => {
  return httpClient.post("presignUrls", data);
};

export const presignUpPics = (data) => {
  return httpClient.post("presignUpPics", data);
};

export const getProductByPrcodeList = (data) => {
  return httpClient.post("getProductByPrcodeList", data);
};

export const getProductByCategory = (data) => {
  return httpClient.post("getProductByCategory", data);
};

export const updateSlotIndex = (data) => {
  return httpClient.post("updateSlotIndex", data);
};

export const getProductRelated = (data) => {
  return httpClient.post("getProductRelated", data);
};

export const setProductRelated = (data) => {
  return httpClient.post("setProductRelated", data);
};

export const setProductAttribute = (data) => {
  return httpClient.post("setProductAttribute", data);
};

export const setProductImport = (data) => {
  return httpClient.post("setProductImport", data);
};

export const setProductRangeImport = (data) => {
  return httpClient.post("setProductRangeImport", data);
};

export const setProductMetaImport = (data) => {
  return httpClient.post("setProductMetaImport", data);
};

export const setProductSortCatSku = (data) => {
  return httpClient.post("setProductSortCatSku", data);
};

export const getCouponList = (data) => {
  return httpClient.post("getCouponList", data);
};

export const getCouponById = (data) => {
  return httpClient.post("getCouponById", data);
};

export const getCoupon2List = (data) => {
  return httpClient.post("getCoupon2List", data);
};

export const getCoupon2ById = (data) => {
  return httpClient.post("getCoupon2ById", data);
};

export const refreshCoupon = (data) => {
  return httpClient.post("refreshCoupon", data);
};

export const createCoupon = (data) => {
  return httpClient.post("createCoupon", data);
};

export const updateCoupon = (data) => {
  return httpClient.post("updateCoupon", data);
};

export const createCoupon2 = (data) => {
  return httpClient.post("createCoupon2", data);
};

export const updateCoupon2 = (data) => {
  return httpClient.post("updateCoupon2", data);
};

export const getManualStockList = (data) => {
  return httpClient.post("getManualStockList", data);
};

export const getManualStockById = (data) => {
  return httpClient.post("getManualStockById", data);
};

export const createManualStock = (data) => {
  return httpClient.post("createManualStock", data);
};

export const updateManualStock = (data) => {
  return httpClient.post("updateManualStock", data);
};

export const getPaymentLog = (data) => {
  return httpClient.post("getPaymentLog", data);
};

export const getOrderList = (data) => {
  return httpClient.post("getOrderList", data);
};

export const getOrder2List = (data) => {
  return httpClient.post("getOrder2List", data);
};

export const getOrderById = (data) => {
  return httpClient.post("getOrderById", data);
};

export const getUserList = (data, userCode="") => {
  // return httpClient.post("getUserList", data);

  // if (userCode == "91016361-25af-4e56-89e0-dc77fe58cf05")
    // return auth.getUserList(data);
  // else
  //   return httpClient.post("getUserList", data);

  // if (userCode == "0ae45829-7c55-4cbb-a1f6-e81936c91255" || 
  //   userCode == "85211595-29ec-4cb0-9fb7-781aaefe9c4f" ||
  //   userCode == "91016361-25af-4e56-89e0-dc77fe58cf05" ||
  //   userCode == "dcb9b390-f12c-4009-ba62-c3f788ffca76" ||
  //   userCode == "eb9b6d76-766a-487f-9a84-bcc331cbcaf2" ||
  //   userCode == "f8c3adfe-ff82-445c-93dc-5f52cbdb4e89")
  //   return auth.getUserList(data);
  // else
    return httpClient.post("getUserList", data);
};

export const getUserById = (data) => {
  // console.log('-- getUserById --', data.userCode);
  // if (data.userCode == "0ae45829-7c55-4cbb-a1f6-e81936c91255" || 
  //   data.userCode == "85211595-29ec-4cb0-9fb7-781aaefe9c4f" ||
  //   data.userCode == "91016361-25af-4e56-89e0-dc77fe58cf05" ||
  //   data.userCode == "dcb9b390-f12c-4009-ba62-c3f788ffca76" ||
  //   data.userCode == "eb9b6d76-766a-487f-9a84-bcc331cbcaf2" ||
  //   data.userCode == "f8c3adfe-ff82-445c-93dc-5f52cbdb4e89")
    // return auth.getUserInfo(data.userCode);
  // else
    return httpClient.post("getUserById", data);
};

export const getUserByName = (data) => {
  // console.log('-- getUserById --', data.userCode);
  // if (data.userCode == "0ae45829-7c55-4cbb-a1f6-e81936c91255" || 
  //   data.userCode == "85211595-29ec-4cb0-9fb7-781aaefe9c4f" ||
  //   data.userCode == "91016361-25af-4e56-89e0-dc77fe58cf05" ||
  //   data.userCode == "dcb9b390-f12c-4009-ba62-c3f788ffca76" ||
  //   data.userCode == "eb9b6d76-766a-487f-9a84-bcc331cbcaf2" ||
  //   data.userCode == "f8c3adfe-ff82-445c-93dc-5f52cbdb4e89")
    // return auth.getUserInfo(data.userCode);
  // else
    return httpClient.post("getUserByName", data);
};

// export const createUser = (data) => {
//   return httpClient.post("createUser", data);
// };

// export const updateUser = (data) => {
//   return httpClient.post("updateUser", data);
// };

export const updatePicking = (data) => {
  return httpClient.post("updatePicking", data);
};

export const getInventory = (data) => {
  return httpClient.post("getInventory", data);
};

export const getSaleReport = (data) => {
  return httpClient.post("getSaleReport", data);
};

export const getReports = (data) => {
  return httpClient.post("getReports", data);
};

export const getVoucherList = (data) => {
  return httpClient.post("getVoucherList", data);
};

export const getVoucherById = (data) => {
  return httpClient.post("getVoucherById", data);
};

export const confirmAutoRefund = (data) => {
  return httpClient.post("confirmAutoRefund", data);
};

export const createVoucher = (data) => {
  return httpClient.post("createVoucher", data);
};

export const getArticle = (data) => {
  return httpClient.post("getArticle", data);
};

export const getArticleById = (data) => {
  return httpClient.post("getArticleById", data);
};

export const createArticle = (data) => {
  return httpClient.post("createArticle", data);
};

export const updateArticle = (data) => {
  return httpClient.post("updateArticle", data);
};

export const searchProductSeq = (data) => {
  return httpClient.post("searchProductSeq", data);
};

export const updateProductSeq = (data) => {
  return httpClient.post("updateProductSeq", data);
};

export const searchBarcode = (data) => {
  return httpClient.post("searchBarcode", data);
};

export const getCardType = (data) => {
  return httpClient.post("getCardType", data);
};

export const getPendingItems = (data) => {
  return httpClient.post("getPendingItems", data);
};

export const getSendNotifyList = (data) => {
  return httpClient.post("getSendNotifyList", data);
};

export const getSendNotifyById = (data) => {
  return httpClient.post("getSendNotifyById", data);
};

export const createSendNotify = (data) => {
  return httpClient.post("createSendNotify", data);
};

export const updateSendNotify = (data) => {
  return httpClient.post("updateSendNotify", data);
};

export const refreshPuppeteerRender = (data) => {
  return httpClient.post("refreshPuppeteerRender", data);
};

export const getDeliveryStatus = (data) => {
  return httpClient.post("getDeliveryStatus", data);
};

export const getCampaignTracking = (data) => {
  return httpClient.post("getCampaignTracking", data);
};

//---
export const getCoupon3List = (data) => {
  return httpClient.post("getCoupon3List", data);
};

export const getCoupon3ById = (data) => {
  return httpClient.post("getCoupon3ById", data);
};

export const createCoupon3 = (data) => {
  return httpClient.post("createCoupon3", data);
};

export const updateCoupon3 = (data) => {
  return httpClient.post("updateCoupon3", data);
};

export const updateDailyReport = (data) => {
  return httpClient.post("updateDailyReport", data);
};