<template>
  <v-container v-if="loading">
    <div class="text-center">
      <v-progress-circular
        indeterminate
        :size="80"
        :width="8"
        color="primary">
      </v-progress-circular>
    </div>
  </v-container>
  <v-container fluid v-else>
    <v-row class="justify-center">
      <v-col cols="12">
        <v-card class="mx-10  pa-5" outlined>
          <v-form @submit.prevent="submit">
            <template>
              <v-row class="ma-0">
                <v-spacer></v-spacer>
                <h1>Banner Category Sorting</h1>
                <v-spacer></v-spacer>
              </v-row>
            </template>

            <br />
            <template>
              <v-row class="ma-0">
                <v-col cols="5">
                  <v-combobox 
                    v-model="categoryLv1Select"
                    :items="categoryLv1" 
                    label="Select Category"
                    item-text="groupName" 
                    item-value="groupName" 
                    return-object
                  ></v-combobox>
                </v-col>
                <v-col cols="7" align-self="center">
                  <v-btn @click="searchClick" color="primary" dark class="mb-2">
                    <v-icon left>search</v-icon>
                    <span>Search</span>
                  </v-btn>
                </v-col>
              </v-row>
            </template>

            <!-- <br /> -->
            <template>
              <div class="row">
                <div class="col-12">
                  <table class="table table-striped" width="100%">
                    <thead class="thead-dark" align-center>
                      <tr>
                        <th scope="col" style="text-align: left">Image</th>
                        <th scope="col" style="text-align: left">Banner Id</th>
                        <th scope="col" style="text-align: left">Name</th>
                        <!-- <th scope="col" style="text-align: left">Category</th> -->
                        <th scope="col" style="text-align: left">Slot Index</th>
                        <th scope="col" style="text-align: left">Start Datetime</th>
                        <th scope="col" style="text-align: left">End Datetime</th>
                        <th scope="col" style="text-align: left">Enabled</th>
                      </tr>
                    </thead>
                    <draggable v-model="mDataArray" tag="tbody">
                      <tr v-for="item in mDataArray" :key="item.cprcode">
                        <td>
                            <v-img
                              contain
                              class="ma-2"
                              :eager="true"
                              :src="item.metadata.imageUrl[0]"
                              lazy-src="@/assets/noimage.png"
                              aspect-ratio="1"
                              min-width="80"
                              max-width="80"
                              max-height="80"
                            >
                              <template v-slot:placeholder>
                                <v-row
                                  class="fill-height ma-0"
                                  align="center"
                                  justify="center"
                                >
                                  <v-progress-circular v-show="loading"
                                    indeterminate
                                    color="grey lighten-5"
                                  ></v-progress-circular>
                                </v-row>
                              </template>
                            </v-img>
                        </td>
                        <td>{{ item.groupId }}</td>
                        <td>{{ item.groupName }}</td>
                        <!-- <td>{{ item.metadata.categorylv1Name }}</td> -->
                        <td>{{ item.metadata.slotIndex }}</td>
                        <td>{{ item.metadata.startDate }}</td>
                        <td>{{ item.metadata.endDate }}</td>
                        <td>
                          <v-checkbox
                            readonly
                            v-model="item.enabled"
                          ></v-checkbox>
                        </td>
                        <!-- <small>{{item.metadata.slotIndex}}</small> -->
                      </tr>
                    </draggable>
                  </table>
                </div>
              </div>
            </template>

            <br />
            <v-row>
              <v-spacer></v-spacer>
              <v-btn class="mr-4" @click="cancel">
                Cancel
              </v-btn>

              <v-btn color="primary" type="submit" v-if="userCode != '9a0064aa-8c44-4597-b60f-7dc8dcb8c532'">
                Confirm
              </v-btn>
            </v-row>
          </v-form>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import api from "@/services/lambdaApi";
import draggable from 'vuedraggable'

export default {
  name: "banner-category-sort",
  components:{
    draggable
  },
  data: () => ({
    loading: false,
    categoryLv1: [],
    categoryLv1Select: null,
    mDataArray: []
  }),
  async mounted() {
    try {
      this.loading = true;
      let group = { groupId: 0 }
      let categoryLv1 = await api.getCategoryLv1(group);
      this.categoryLv1 = categoryLv1.data;
      this.categoryLv1.splice(0, 1)
      // console.log("--- categoryLv1 ---", JSON.stringify(this.categoryLv1));
    // } catch (error) {
    }
    finally {
      this.loading = false;
    } 
  },
  methods: {
    // even: function(arr) {
    //   // Set slice() to avoid to generate an infinite loop!
    //   return arr.slice().sort(function(a, b) {
    //     return a.metadata.slotIndex - b.metadata.slotIndex;
    //   });
    // },
    async searchClick() {
      if (this.categoryLv1Select != null) {
        try {
          this.loading = true;
          let data = { 
            groupId: 8000000000, 
            bannerType: "category", 
            enabled: true, 
            sortSlotIndex: true, 
            categorylv1Name: this.categoryLv1Select.groupName
          };
          // console.log("--- data ---", data);
          let result = await api.getBanner(data);
          // console.log("--- banner sort list ---", result.data);
          this.mDataArray = result.data;
        // } catch (error) {
        }
        finally {
          this.loading = false;
        }
      }
    },
    cancel() {
      this.$router.back();
    },
    async submit() {
      try {
        this.loading = true;
        let groupList = [];
        let i = 0;
        this.mDataArray.forEach(item => {
          groupList.push({ groupId: item.groupId, slotIndex: ++i });
        });
        console.log('request', JSON.stringify(groupList));
        let result = await api.updateSlotIndex(groupList);
        console.log('result', result);
        this.$router.back();
      // } catch (error) {
      }
      finally {
        this.loading = false;
      }
    },
  },
  computed: {
      userCode() {
        return this.$store.getters["userInfo"]["userCode"]?.toString() ?? "";
      }
  }
};
</script>

<style scoped>
table {
  border-spacing: 0 0.5rem;
}
tbody tr:nth-of-type(odd) {
   background-color: rgba(0, 0, 0, .05);
 }
</style>