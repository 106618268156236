<template>
    <v-container v-if="loading">
      <div class="text-center">
        <v-progress-circular
          indeterminate
          :size="80"
          :width="8"
          color="primary">
        </v-progress-circular>
      </div>
    </v-container>
    <v-container fluid v-else>
      <v-row class="justify-center">
        <v-col cols="12">
          <v-card class="mx-0 pa-5" outlined>
            <v-img
              class="mb-5"
              contain
              :eager="true"
              :src="getImageUrl(product.image)"
              alt=""
              height="200"/>
            <v-form @submit.prevent="submit">
              <v-row>
                <v-col cols="12">
                  <v-text-field
                    outlined
                    v-model="product.cprcode"
                    label="Pr Code"
                    :disabled="true"
                  ></v-text-field>

                  <v-text-field
                    outlined
                    v-model="product.hema_name_th"
                    label="Pr Name (TH)"
                    :disabled="true"
                  ></v-text-field>

                  <v-text-field
                    outlined
                    v-model="product.hema_name_en"
                    label="Pr Name (EN)"
                    :disabled="true"
                  ></v-text-field>

                  <v-text-field
                    outlined
                    clearable
                    v-model="product.meta_title2"
                    label="Meta Title"
                  ></v-text-field>

                  <v-textarea
                    outlined
                    clearable
                    v-model="product.meta_description2"
                    label="Meta Description"
                    height="200"
                  ></v-textarea>

                  <v-textarea
                    outlined
                    clearable
                    v-model="product.meta_keywords2"
                    label="Meta Keywords"
                    height="200"
                  ></v-textarea>
                  
                  <v-text-field
                    outlined
                    clearable
                    v-model="group_link.anchor_text"
                    label="Anchor text"
                  ></v-text-field>

                  <v-text-field
                    outlined
                    clearable
                    v-model="group_link.link"
                    label="Anchor link"
                  ></v-text-field>
                </v-col>
              </v-row>

              <v-row>
                <v-card class="mx-0 pa-5" width="100%">
                <h2>Alt Text</h2>
                <div class="table_sub" width="100%">
                  <table class="table table-striped" width="100%">
                    <thead class="head-dark" align-center>
                      <tr>
                        <th width="10%" style="text-align:left;word-wrap: break-word;">Image</th>
                        <th width="15%" style="text-align:left;word-wrap: break-word;">Image Name</th>
                        <th width="auto" style="text-align:left;word-wrap: break-word;">Alt Text</th>
                        <th width="5%" style="text-align:left;word-wrap: break-word;">Edit</th>
                      </tr>
                    </thead>
                    <tbody>
                        <template v-for="item in image_alt_text">
                        <tr>
                          <td style="vertical-align: top;">
                            <v-img
                              contain
                              class="ma-2"
                              :eager="true"
                              :src="getImageUrl(item.image_name)"
                              lazy-src="@/assets/noimage.png"
                              aspect-ratio="1"
                              min-width="80"
                              max-width="80"
                              max-height="80">
                              <template v-slot:placeholder>
                                <v-row
                                  class="fill-height ma-0"
                                  align="center"
                                  justify="center">
                                  <v-progress-circular v-show="loading"
                                    indeterminate
                                    color="grey lighten-5"
                                  ></v-progress-circular>
                                </v-row>
                              </template>
                            </v-img>
                          </td>
                          <td style="text-align:left;word-wrap: break-word; vertical-align: top;">{{ item.image_name ?? "" }}</td>
                          <td style="text-align:left;word-wrap: break-word; vertical-align: top;">{{ item.alt_text ?? "" }}</td>
                          <td style="text-align:left;word-wrap: break-word; vertical-align: top;">
                            <v-icon @click="updateAltText(item)">edit</v-icon>
                          </td>
                        </tr>
                        </template>
                    </tbody>
                  </table>
                </div>
                </v-card>
              </v-row>
  
              <v-divider></v-divider>  
              <br />
              <v-row>
                <v-spacer></v-spacer>
                <v-btn class="mr-4" @click="cancel">
                  Cancel
                </v-btn>
  
                <v-btn color="primary" type="submit" v-if="userCode != '9a0064aa-8c44-4597-b60f-7dc8dcb8c532'">
                  Confirm
                </v-btn>
              </v-row>
            </v-form>
          </v-card>
        </v-col>
      </v-row>

      <template>
        <v-row justify="center">
          <v-dialog
            v-model="isUpdateAltText"
            max-width="700px">
            <v-card>
              <v-card-title>Update Alt Text</v-card-title>
              <v-divider></v-divider>
              <v-row class="ma-2">
                <v-col cols="12">
                  <v-img
                    class="mb-5"
                    contain
                    :eager="true"
                    :src="getImageUrl(alt_text_update.image_name)"
                    alt=""
                    height="200"/>
                  <v-text-field
                    outlined
                    v-model="alt_text_update.image_name"
                    label="Image Name"
                    clearable
                    disabled
                  ></v-text-field>
                  <v-textarea
                    outlined
                    v-model="alt_text_update.alt_text"
                    label="Alt Text"
                    height="200"
                  ></v-textarea>
                </v-col>
              </v-row>
              <v-divider></v-divider>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn
                  color="blue darken-1"
                  text
                  @click="isUpdateAltText = false">
                  Close
                </v-btn>
                <!-- <v-divider class="mx-4" inset vertical></v-divider>
                <v-btn color="primary" @click="saveCampaign">
                  Ok
                </v-btn> -->
              </v-card-actions>
            </v-card>
          </v-dialog>
        </v-row>
    </template>
    </v-container>
</template>
  
<script>
import { imageUrl } from "@/services/constants";
import geoService from "@/services/geoService";
  
export default {
    name: "geoproduct-edit",
    // components:{
    // },
    data: () => ({
      loading: false,
      isUpdateAltText: false,
      cprcode: 0,
      image_alt_text: [],
      alt_text_update: {},
      group_link: {
        anchor_text: "",
        link: ""
      },
      product: {
        cprcode: 0,
        iprcode: 0,
        hema_name_en: "",
        hema_name_th: "",
        meta_title2: "",
        meta_keywords2: "",
        meta_description2: "",
        group_link: {
          anchor_text: "",
          link: ""
        },
        image_alt_text: [],
        master_online: false,
        image: ""
      },
    }),
    async mounted() {
      try {
        this.loading = true;
        let cprcode = parseInt(this.$route.params.id);
        let result = await geoService.getProductSeo(cprcode);
        this.product = result;
        this.image_alt_text = this.product?.image_alt_text ?? []
        this.group_link = {
          anchor_text: this.product?.group_link?.anchor_text ?? "",
          link: this.product?.group_link?.link ?? ""
        };
      } catch (error) {
        alert(error)
      }
      finally {
        this.loading = false;
      } 
    },
  
    methods: {
      getImageUrl(image_name) {
        return `${imageUrl}/${image_name}`;
      },
      updateAltText(item) {
        this.alt_text_update = item;
        this.isUpdateAltText = true;
      },
      cancel() {
        this.$router.back();
      },
      async submit() {
        try {
          this.loading = true;
          let data = {
            cprcode: this.product.cprcode,
            meta_title2: this.product.meta_title2,
            meta_keywords2: this.product.meta_keywords2,
            meta_description2: this.product.meta_description2,
            image_alt_text: this.image_alt_text,
            group_link: {
              anchor_text: this.group_link.anchor_text,
              link: this.group_link.link
            }
          };
          // let result = await geoService.updateProductSeo(data);
          await geoService.updateProductSeo(data);
          this.$router.back();
        } catch (error) {
          alert(error);
        }
        finally {
          this.loading = false;
        }
      },
    },
  computed: {
    userCode() {
      return this.$store.getters["userInfo"]["userCode"]?.toString() ?? "";
    },
  }
  };
</script>
  
<style scoped>
  table {
    border-spacing: 0 0.5rem;
    font-size: small;
  }
  .v-progress-circular {
    margin: 1rem;
  }
  v-data-table table {
    border-spacing: 0 0.5rem;
  }
  tbody tr:nth-of-type(odd) {
    background-color: rgba(0, 0, 0, 0.05);
  }
  .header {
    font-size: 18px;
    font-weight: bold;
    color: #D50000;
  }

  .table_sub {
    height: 420px;
    overflow: auto;
  }
</style>