<template>
  <v-container v-if="loading">
    <div class="text-center">
      <v-progress-circular
        indeterminate
        :size="80"
        :width="8"
        color="primary">
      </v-progress-circular>
    </div>
  </v-container>
  <v-container fluid v-else>
    <v-row class="justify-center">
      <v-col cols="12">
        <v-card class="mx-1 pa-5" outlined>
          <v-img
            class="mb-5"
            contain
            :eager="true"
            :src="product.image"
            alt=""
            height="200"
          />
          <v-form @submit.prevent="submit">
            <v-row>

              <!-- Left -->
              <v-col cols="6">
                <v-row>
                  <v-col cols="4">
                    <v-text-field
                      outlined
                      v-model="product.cprcode"
                      label="(C) Pr Code"
                      disabled
                    ></v-text-field>
                  </v-col>
                  <v-col cols="4">
                    <v-text-field
                      outlined
                      v-model="product.iprcode"
                      label="(I) Pr Code"
                      disabled
                    ></v-text-field>
                  </v-col>
                  <v-col cols="4">
                    <v-checkbox
                      v-model="product.master_online"
                      label="Online"
                    ></v-checkbox>
                  </v-col>
                  <!-- <v-col cols="3">
                    <v-checkbox
                      v-model="product.enabled"
                      label="Enabled"
                    ></v-checkbox>
                  </v-col> -->
                </v-row>

                <v-text-field
                  outlined
                  v-model="product.hema_name_en"
                  label="Pr Name (EN)"
                  required
                ></v-text-field>

                <v-text-field
                  outlined
                  v-model="product.hema_name_th"
                  label="Pr Name (TH)"
                  required
                ></v-text-field>

                <v-text-field
                  outlined
                  v-model="product.hema_name_ru"
                  label="Pr Name (RU)"
                  required
                ></v-text-field>

                <v-text-field
                  outlined
                  v-model="product.hema_name_cn"
                  label="Pr Name (CN)"
                  required
                ></v-text-field>

                <v-combobox 
                  label="Category Level 1"
                  outlined 
                  v-model="categoryLv1Select"
                  :items="categoryLv1" 
                  item-text="online_category_l1_en" 
                  item-value="online_category_l1_en" 
                  return-object
                ></v-combobox>

                <v-combobox 
                  label="Category Level 2"
                  outlined 
                  v-model="categoryLv2Select"
                  :items="filterCategoryLv2" 
                  item-text="online_category_l2_en" 
                  item-value="online_category_l2_en" 
                  return-object
                ></v-combobox>

                <v-combobox
                  label="Category Level 3" 
                  outlined 
                  v-model="categoryLv3Select"
                  :items="filterCategoryLv3" 
                  item-text="online_category_l3_en" 
                  item-value="online_category_l3_en" 
                  return-object
                ></v-combobox>

                <v-text-field
                  outlined
                  v-model="product.pr_country_en"
                  label="Country"
                ></v-text-field>

                <v-text-field
                  outlined
                  v-model="product.hema_brand_en"
                  label="Brand"
                ></v-text-field>

                 <v-text-field
                  outlined
                  v-model="product.hema_sizedesc"
                  label="Size Desc"
                ></v-text-field>

                <v-text-field
                  outlined
                  v-model="product.meta_title"
                  label="Meta Title"
                ></v-text-field>

                <v-text-field
                  outlined
                  v-model="product.meta_keywords"
                  label="Meta Keywords"
                ></v-text-field>

                <v-textarea
                  outlined
                  v-model="product.meta_description"
                  label="Meta Description"
                ></v-textarea>

                <!-- <v-text-field
                  outlined
                  v-model="product.meta_description"
                  label="Meta Description"
                ></v-text-field> -->

              </v-col>

              <!-- Right -->
              <v-col cols="6">

                <v-row>
                  <v-col cols="6">
                    <!-- <v-text-field
                      outlined
                      v-model="product.consign_inv"
                      suffix="PCS"
                      type="number"
                      label="Consign Inventory"
                    ></v-text-field> -->

                    <v-text-field
                      outlined
                      v-model="product.psqty"
                      suffix="PCS"
                      type="number"
                      label="Pack Size Qty"
                      readonly
                    ></v-text-field>

                    <v-text-field
                      outlined
                      v-model="product.height"
                      type="number"
                      label="Height"
                    ></v-text-field>

                    <v-text-field
                      outlined
                      v-model="product.width"
                      type="number"
                      label="Width"
                    ></v-text-field>

                    <v-text-field
                      outlined
                      v-model="product.depth"
                      type="number"
                      label="Depth"
                    ></v-text-field>

                    <!-- <v-text-field
                      outlined
                      v-model="product.portion_size"
                      label="Portion"
                    ></v-text-field> -->

                    <!-- <v-select
                      outlined
                      v-model="product.preorder_delivery_type"
                      :items="preorder"
                      label="Preorder Delivery Type"
                    ></v-select> -->

                    <!-- <v-combobox
                      outlined
                      v-model="product.preorder_delivery_type"
                      label="Preorder Delivery Type"
                      :items="preorder"
                    ></v-combobox> -->
                  </v-col>

                  <v-col cols="6">
                    <v-text-field
                      outlined
                      v-model="product.warehouse"
                      type="number"
                      label="Warehouse"
                    ></v-text-field>

                    <v-text-field
                      outlined
                      v-model="product.portion_size"
                      label="Portion"
                    ></v-text-field>

                    <!-- <v-text-field
                      outlined
                      v-model="product.priority_score"
                      type="number"
                      label="Priority Score"
                    ></v-text-field> -->

                    <v-text-field
                      outlined
                      v-model="product.weight"
                      type="number"
                      label="Weight"
                    ></v-text-field>

                    <!-- preorder_fix_date -->
                    <!-- <v-menu
                      ref="memu1"
                      v-model="memu1"
                      :close-on-content-click="false"
                      :return-value.sync="date"
                      transition="scale-transition"
                      offset-y
                      min-width="auto"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                          outlined
                          v-model="product.preorder_fix_date"
                          label="Preorder Delivery Date"
                          readonly
                          v-bind="attrs"
                          v-on="on"
                          clearable
                          @click:clear="date = null"
                        ></v-text-field>
                      </template>
                      <v-date-picker
                        v-model="product.preorder_fix_date"
                        no-title
                        scrollable
                      >
                        <v-spacer></v-spacer>
                        <v-btn
                          text
                          color="primary"
                          @click="memu1 = false"
                        >
                          Cancel
                        </v-btn>
                        <v-btn
                          text
                          color="primary"
                          @click="$refs.memu1.save(date)"
                        >
                          OK
                        </v-btn>
                      </v-date-picker>
                    </v-menu> -->

                    <!-- <v-text-field
                      outlined
                      v-model="product.preorder_relative_day"
                      type="number"
                      label="Preorder Relative Day"
                    ></v-text-field> -->
                  </v-col>
                </v-row>

                <!-- <v-textarea
                  outlined
                  v-model="product.product_detail_description"
                  label="Product Detail Description"
                ></v-textarea> -->

                <template>
                  <v-row
                    align="center"
                    justify="space-around"
                  >
                    <v-btn
                      depressed
                      color="primary"
                      @click="zoneAllClick"
                    >
                      {{ allLabel }}
                    </v-btn>
                    <v-btn
                      depressed
                      color="primary"
                      @click="zone1Click"
                    >
                      กรุงเทพฯ
                    </v-btn>
                    <v-btn
                      depressed
                      color="primary"
                      @click="zone2Click"
                    >
                      ภูเก็ต
                    </v-btn>
                    <v-btn
                      depressed
                      color="primary"
                      @click="zone3Click"
                    >
                      หัวหิน
                    </v-btn>
                    <v-btn
                      depressed
                      color="primary"
                      @click="zone4Click"
                    >
                      พัทยา
                    </v-btn>
                  </v-row>
                </template>

                <br />
                <!-- <p>{{ product.avail_store }}</p> -->
                <div class="table_store">
                  <table class="table table-striped" width="100%">
                      <tr>
                        <th width="20%" style="text-align:left">Store Code</th>
                        <th width="40%" style="text-align:left">Store Name</th>
                        <th width="20%" style="text-align:left">Range</th>
                        <th width="20%" style="text-align:left">Visibility</th>
                            <!-- <v-checkbox
                                height="1"
                                v-model="selectAll"
                                label="Select"
                                @change="onSelectAllChange"
                            ></v-checkbox> -->
                      </tr>
                    <tbody>
                      <template v-for="store in avail_store">
                      <!-- <tr :key="store.storeCode"> -->
                      <tr>
                          <td>{{ store.storeCode }}</td>
                          <td>{{ store.storeName }}</td>
                          <td>
                          <v-checkbox
                              height="1"
                              v-model="store.range"
                          ></v-checkbox>
                          </td>
                          <v-checkbox
                              height="1"
                              v-model="store.visibility"
                          ></v-checkbox>
                      </tr>
                      </template>
                    </tbody>
                  </table>
                </div>

                <!-- <v-row>
                  <v-col cols="3">
                    <template>
                      <v-container fluid>
                        <v-checkbox
                          v-model="product.avail_store"
                          label="1000"
                          value="1000"
                        ></v-checkbox>
                        <v-checkbox
                          v-model="product.avail_store"
                          label="1003"
                          value="1003"
                        ></v-checkbox>
                        <v-checkbox
                          v-model="product.avail_store"
                          label="1015"
                          value="1015"
                        ></v-checkbox>
                        <v-checkbox
                          v-model="product.avail_store"
                          label="1016"
                          value="1016"
                        ></v-checkbox>
                      </v-container>
                    </template>
                  </v-col>
                  <v-col cols="3">
                    <template>
                      <v-container fluid>
                        <v-checkbox
                          v-model="product.avail_store"
                          label="1021"
                          value="1021"
                        ></v-checkbox>
                        <v-checkbox
                          v-model="product.avail_store"
                          label="1022"
                          value="1022"
                        ></v-checkbox>
                        <v-checkbox
                          v-model="product.avail_store"
                          label="1023"
                          value="1023"
                        ></v-checkbox>
                        <v-checkbox
                          v-model="product.avail_store"
                          label="1026"
                          value="1026"
                        ></v-checkbox>
                      </v-container>
                    </template>
                  </v-col>

                  <v-col cols="3">
                    <template>
                      <v-container fluid>
                        <v-checkbox
                          v-model="product.avail_store"
                          label="1032"
                          value="1032"
                        ></v-checkbox>
                        <v-checkbox
                          v-model="product.avail_store"
                          label="1038"
                          value="1038"
                        ></v-checkbox>
                        <v-checkbox
                          v-model="product.avail_store"
                          label="1039"
                          value="1039"
                        ></v-checkbox>
                        <v-checkbox
                          v-model="product.avail_store"
                          label="1040"
                          value="1040"
                        ></v-checkbox>
                      </v-container>
                    </template>
                  </v-col>

                  <v-col cols="3">
                    <template>
                      <v-container fluid>
                        <v-checkbox
                          v-model="product.avail_store"
                          label="1041"
                          value="1041"
                        ></v-checkbox>
                        <v-checkbox
                          v-model="product.avail_store"
                          label="1047"
                          value="1047"
                        ></v-checkbox>
                        <v-checkbox
                          v-model="product.avail_store"
                          label="1048"
                          value="1048"
                        ></v-checkbox>
                        <v-checkbox
                          v-model="product.avail_store"
                          label="1049"
                          value="1049"
                        ></v-checkbox>
                      </v-container>
                    </template>
                  </v-col>
                </v-row> -->
                <!-- <p>{{ product.avail_store }}</p> -->
              </v-col>
            </v-row>

            <!-- <br /> -->
            <h1>Product Attribute</h1>

            <template>
              <v-card>
                <v-tabs
                  v-model="tab"
                  background-color="primary"
                  centered
                  dark
                  icons-and-text
                >
                  <v-tabs-slider></v-tabs-slider>

                  <v-tab href="#tab-1">
                    Alcohol
                    <v-icon>ballot</v-icon>
                  </v-tab>

                  <v-tab href="#tab-2">
                    Butcher
                    <v-icon>ballot</v-icon>
                  </v-tab>

                  <v-tab href="#tab-3">
                    Fruit & Veg Toy
                    <v-icon>ballot</v-icon>
                  </v-tab>

                  <v-tab href="#tab-4">
                    HBA & Household
                    <v-icon>ballot</v-icon>
                  </v-tab>

                  <v-tab href="#tab-5">
                    Others
                    <v-icon>ballot</v-icon>
                  </v-tab>
                </v-tabs>

                <v-tabs-items v-model="tab">
                  <v-tab-item
                    v-for="i in 5"
                    :key="i"
                    :value="'tab-' + i"
                  >
                    <!-- Alcohol -->
                    <v-card class="ma-5" v-if="tab=='tab-1'" flat>
                      <v-row>
                        <v-col cols="3">
                          <div class="header">รายละเอียด ภาษาอังกฤษ</div><br />
                          <v-textarea
                            outlined
                            v-model="product_attribute_1.origin"
                            label="Origin"
                          ></v-textarea>

                          <v-textarea
                            outlined
                            v-model="product_attribute_1.description"
                            label="Description"
                          ></v-textarea>

                          <v-text-field
                            outlined
                            v-model="product_attribute_1.grape"
                            label="Grape"
                          ></v-text-field>

                          <v-text-field
                            outlined
                            v-model="product_attribute_1.type"
                            label="Type"
                          ></v-text-field>

                          <v-combobox
                            v-if="product.online_category_l2_en=='Wine'"
                            outlined
                            v-model="product_attribute_1.style"
                            label="Style"
                            :items="['A Light bodied', 'B Light to medium bodied', 'C Medium bodied', 'D Medium to full bodied', 'E Full bodied']"
                          ></v-combobox>

                          <v-combobox
                            v-else-if="product.online_category_l2_en=='Champagne'"
                            outlined
                            v-model="product_attribute_1.style"
                            label="Style"
                            :items="['1 Very dry', '2 Dry', '3 Very medium', '4 Medium', '5 Medium Sweet', '6 Sweet', '7 Very Sweet', '8 Rich']"
                          ></v-combobox>

                          <v-text-field
                            v-else
                            outlined
                            v-model="product_attribute_1.style"
                            label="Style"
                          ></v-text-field>

                          <v-text-field
                            outlined
                            v-model="product_attribute_1.alcohol_vol"
                            label="Alcohol Vol"
                          ></v-text-field>

                          <v-text-field
                            outlined
                            v-model="product_attribute_1.food_paring"
                            label="Food paring"
                          ></v-text-field>
                        </v-col>

                        <v-col cols="3">
                          <div class="header">รายละเอียด ภาษาไทย</div><br />
                          <v-textarea
                            outlined
                            v-model="product_attribute_1.origin_th"
                            label="Origin"
                          ></v-textarea>

                          <v-textarea
                            outlined
                            v-model="product_attribute_1.description_th"
                            label="Description"
                          ></v-textarea>

                          <v-text-field
                            outlined
                            v-model="product_attribute_1.grape_th"
                            label="Grape"
                          ></v-text-field>

                          <v-text-field
                            outlined
                            v-model="product_attribute_1.type_th"
                            label="Type"
                          ></v-text-field>

                          <!-- <v-combobox
                            v-if="product.online_category_l2_en=='Wine'"
                            outlined
                            v-model="product_attribute_1.style"
                            label="Style"
                            :items="['A Light bodied', 'B Light to medium bodied', 'C Medium bodied', 'D Medium to full bodied', 'E Full bodied']"
                          ></v-combobox>

                          <v-combobox
                            v-else-if="product.online_category_l2_en=='Champagne'"
                            outlined
                            v-model="product_attribute_1.style"
                            label="Style"
                            :items="['1 Very dry', '2 Dry', '3 Very medium', '4 Medium', '5 Medium Sweet', '6 Sweet', '7 Very Sweet', '8 Rich']"
                          ></v-combobox> -->

                          <v-text-field
                            outlined
                            v-model="product_attribute_1.style_th"
                            label="Style"
                          ></v-text-field>

                          <v-text-field
                            outlined
                            v-model="product_attribute_1.alcohol_vol_th"
                            label="Alcohol Vol"
                          ></v-text-field>

                          <v-text-field
                            outlined
                            v-model="product_attribute_1.food_paring_th"
                            label="Food paring"
                          ></v-text-field>
                        </v-col>

                        <v-col cols="3">
                          <div class="header">รายละเอียด ภาษารัสเซีย</div><br />
                          <v-textarea
                            outlined
                            v-model="product_attribute_1.origin_ru"
                            label="Origin"
                          ></v-textarea>

                          <v-textarea
                            outlined
                            v-model="product_attribute_1.description_ru"
                            label="Description"
                          ></v-textarea>

                          <v-text-field
                            outlined
                            v-model="product_attribute_1.grape_ru"
                            label="Grape"
                          ></v-text-field>

                          <v-text-field
                            outlined
                            v-model="product_attribute_1.type_ru"
                            label="Type"
                          ></v-text-field>

                          <!-- <v-combobox
                            v-if="product.online_category_l2_en=='Wine'"
                            outlined
                            v-model="product_attribute_1.style"
                            label="Style"
                            :items="['A Light bodied', 'B Light to medium bodied', 'C Medium bodied', 'D Medium to full bodied', 'E Full bodied']"
                          ></v-combobox>

                          <v-combobox
                            v-else-if="product.online_category_l2_en=='Champagne'"
                            outlined
                            v-model="product_attribute_1.style"
                            label="Style"
                            :items="['1 Very dry', '2 Dry', '3 Very medium', '4 Medium', '5 Medium Sweet', '6 Sweet', '7 Very Sweet', '8 Rich']"
                          ></v-combobox> -->

                          <v-text-field
                            outlined
                            v-model="product_attribute_1.style_ru"
                            label="Style"
                          ></v-text-field>

                          <v-text-field
                            outlined
                            v-model="product_attribute_1.alcohol_vol_ru"
                            label="Alcohol Vol"
                          ></v-text-field>

                          <v-text-field
                            outlined
                            v-model="product_attribute_1.food_paring_ru"
                            label="Food paring"
                          ></v-text-field>
                        </v-col>

                        <v-col cols="3">
                          <div class="header">รายละเอียด ภาษาจีน</div><br />
                          <v-textarea
                            outlined
                            v-model="product_attribute_1.origin_cn"
                            label="Origin"
                          ></v-textarea>

                          <v-textarea
                            outlined
                            v-model="product_attribute_1.description_cn"
                            label="Description"
                          ></v-textarea>

                          <v-text-field
                            outlined
                            v-model="product_attribute_1.grape_cn"
                            label="Grape"
                          ></v-text-field>

                          <v-text-field
                            outlined
                            v-model="product_attribute_1.type_cn"
                            label="Type"
                          ></v-text-field>

                          <!-- <v-combobox
                            v-if="product.online_category_l2_en=='Wine'"
                            outlined
                            v-model="product_attribute_1.style"
                            label="Style"
                            :items="['A Light bodied', 'B Light to medium bodied', 'C Medium bodied', 'D Medium to full bodied', 'E Full bodied']"
                          ></v-combobox>

                          <v-combobox
                            v-else-if="product.online_category_l2_en=='Champagne'"
                            outlined
                            v-model="product_attribute_1.style"
                            label="Style"
                            :items="['1 Very dry', '2 Dry', '3 Very medium', '4 Medium', '5 Medium Sweet', '6 Sweet', '7 Very Sweet', '8 Rich']"
                          ></v-combobox> -->

                          <v-text-field
                            outlined
                            v-model="product_attribute_1.style_cn"
                            label="Style"
                          ></v-text-field>

                          <v-text-field
                            outlined
                            v-model="product_attribute_1.alcohol_vol_cn"
                            label="Alcohol Vol"
                          ></v-text-field>

                          <v-text-field
                            outlined
                            v-model="product_attribute_1.food_paring_cn"
                            label="Food paring"
                          ></v-text-field>
                        </v-col>
                      </v-row>
                    </v-card>
                    <!-- Butcher -->
                    <v-card class="ma-5" v-if="tab=='tab-2'" flat>
                      <v-row>
                        <v-col cols="3">
                          <div class="header">รายละเอียด ภาษาอังกฤษ</div><br />
                          <v-textarea
                            outlined
                            v-model="product_attribute_2.origin"
                            label="Origin"
                          ></v-textarea>

                          <v-text-field
                            outlined
                            v-model="product_attribute_2.breed"
                            label="Breed"
                          ></v-text-field>

                          <v-text-field
                            outlined
                            v-model="product_attribute_2.feed"
                            label="Feed"
                          ></v-text-field>

                          <v-textarea
                            outlined
                            v-model="product_attribute_2.description"
                            label="Description"
                          ></v-textarea>

                          <!-- <v-text-field
                            outlined
                            v-model="product_attribute_2.beef_part"
                            label="Beef Part"
                          ></v-text-field> -->
                        </v-col>
                        <v-col cols="3">
                          <div class="header">รายละเอียด ภาษาไทย</div><br />
                          <v-textarea
                            outlined
                            v-model="product_attribute_2.origin_th"
                            label="Origin"
                          ></v-textarea>

                          <v-text-field
                            outlined
                            v-model="product_attribute_2.breed_th"
                            label="Breed"
                          ></v-text-field>

                          <v-text-field
                            outlined
                            v-model="product_attribute_2.feed_th"
                            label="Feed"
                          ></v-text-field>

                          <v-textarea
                            outlined
                            v-model="product_attribute_2.description_th"
                            label="Description"
                          ></v-textarea>

                          <!-- <v-text-field
                            outlined
                            v-model="product_attribute_2.beef_part_th"
                            label="Beef Part"
                          ></v-text-field> -->
                        </v-col>
                        <v-col cols="3">
                          <div class="header">รายละเอียด ภาษารัสเซีย</div><br />
                          <v-textarea
                            outlined
                            v-model="product_attribute_2.origin_ru"
                            label="Origin"
                          ></v-textarea>

                          <v-text-field
                            outlined
                            v-model="product_attribute_2.breed_ru"
                            label="Breed"
                          ></v-text-field>

                          <v-text-field
                            outlined
                            v-model="product_attribute_2.feed_ru"
                            label="Feed"
                          ></v-text-field>

                          <v-textarea
                            outlined
                            v-model="product_attribute_2.description_ru"
                            label="Description"
                          ></v-textarea>

                          <!-- <v-text-field
                            outlined
                            v-model="product_attribute_2.beef_part_ru"
                            label="Beef Part"
                          ></v-text-field> -->
                        </v-col>
                        <v-col cols="3">
                          <div class="header">รายละเอียด ภาษาจีน</div><br />
                          <v-textarea
                            outlined
                            v-model="product_attribute_2.origin_cn"
                            label="Origin"
                          ></v-textarea>

                          <v-text-field
                            outlined
                            v-model="product_attribute_2.breed_cn"
                            label="Breed"
                          ></v-text-field>

                          <v-text-field
                            outlined
                            v-model="product_attribute_2.feed_cn"
                            label="Feed"
                          ></v-text-field>

                          <v-textarea
                            outlined
                            v-model="product_attribute_2.description_cn"
                            label="Description"
                          ></v-textarea>

                          <!-- <v-text-field
                            outlined
                            v-model="product_attribute_2.beef_part_cn"
                            label="Beef Part"
                          ></v-text-field> -->
                        </v-col>
                      </v-row>
                    </v-card>
                    <!-- Fruit & Veg Toy -->
                    <v-card class="ma-5" v-if="tab=='tab-3'" flat>
                      <v-row>
                        <v-col cols="3">
                          <div class="header">รายละเอียด ภาษาอังกฤษ</div><br />
                          <v-textarea
                            outlined
                            v-model="product_attribute_3.origin"
                            label="Origin"
                          ></v-textarea>

                          <v-textarea
                            outlined
                            v-model="product_attribute_3.description"
                            label="Description"
                          ></v-textarea>
                        </v-col>
                        <v-col cols="3">
                          <div class="header">รายละเอียด ภาษาไทย</div><br />
                          <v-textarea
                            outlined
                            v-model="product_attribute_3.origin_th"
                            label="Origin"
                          ></v-textarea>

                          <v-textarea
                            outlined
                            v-model="product_attribute_3.description_th"
                            label="Description"
                          ></v-textarea>
                        </v-col>
                        <v-col cols="3">
                          <div class="header">รายละเอียด ภาษารัสเซีย</div><br />
                          <v-textarea
                            outlined
                            v-model="product_attribute_3.origin_ru"
                            label="Origin"
                          ></v-textarea>

                          <v-textarea
                            outlined
                            v-model="product_attribute_3.description_ru"
                            label="Description"
                          ></v-textarea>
                        </v-col>
                        <v-col cols="3">
                          <div class="header">รายละเอียด ภาษาจีน</div><br />
                          <v-textarea
                            outlined
                            v-model="product_attribute_3.origin_cn"
                            label="Origin"
                          ></v-textarea>

                          <v-textarea
                            outlined
                            v-model="product_attribute_3.description_cn"
                            label="Description"
                          ></v-textarea>
                        </v-col>
                      </v-row>
                    </v-card>
                    <!-- HBA & Household -->
                    <v-card class="ma-5" v-if="tab=='tab-4'" flat>
                      <v-row>
                        <v-col cols="3">
                          <div class="header">รายละเอียด ภาษาอังกฤษ</div><br />
                          <v-textarea
                            outlined
                            v-model="product_attribute_4.origin"
                            label="Origin"
                          ></v-textarea>

                          <v-textarea
                            outlined
                            v-model="product_attribute_4.description"
                            label="Description"
                          ></v-textarea>

                          <v-textarea
                            outlined
                            v-model="product_attribute_4.ingredients"
                            label="Ingredients"
                          ></v-textarea>
                        </v-col>
                        <v-col cols="3">
                          <div class="header">รายละเอียด ภาษาไทย</div><br />
                          <v-textarea
                            outlined
                            v-model="product_attribute_4.origin_th"
                            label="Origin"
                          ></v-textarea>

                          <v-textarea
                            outlined
                            v-model="product_attribute_4.description_th"
                            label="Description"
                          ></v-textarea>

                          <v-textarea
                            outlined
                            v-model="product_attribute_4.ingredients_th"
                            label="Ingredients"
                          ></v-textarea>
                        </v-col>
                        <v-col cols="3">
                          <div class="header">รายละเอียด ภาษารัสเซีย</div><br />
                          <v-textarea
                            outlined
                            v-model="product_attribute_4.origin_ru"
                            label="Origin"
                          ></v-textarea>

                          <v-textarea
                            outlined
                            v-model="product_attribute_4.description_ru"
                            label="Description"
                          ></v-textarea>

                          <v-textarea
                            outlined
                            v-model="product_attribute_4.ingredients_ru"
                            label="Ingredients"
                          ></v-textarea>
                        </v-col>
                        <v-col cols="3">
                          <div class="header">รายละเอียด ภาษาจีน</div><br />
                          <v-textarea
                            outlined
                            v-model="product_attribute_4.origin_cn"
                            label="Origin"
                          ></v-textarea>

                          <v-textarea
                            outlined
                            v-model="product_attribute_4.description_cn"
                            label="Description"
                          ></v-textarea>

                          <v-textarea
                            outlined
                            v-model="product_attribute_4.ingredients_cn"
                            label="Ingredients"
                          ></v-textarea>
                        </v-col>
                      </v-row>
                    </v-card>
                    <!-- Others -->
                    <v-card class="ma-5" v-if="tab=='tab-5'" flat>
                      <v-row>
                        <v-col cols="3">
                          <div class="header">รายละเอียด ภาษาอังกฤษ</div><br />
                          <v-textarea
                            outlined
                            v-model="product_attribute_5.origin"
                            label="Origin"
                          ></v-textarea>

                          <v-textarea
                            outlined
                            v-model="product_attribute_5.description"
                            label="Description"
                          ></v-textarea>

                          <v-textarea
                            outlined
                            v-model="product_attribute_5.ingredients"
                            label="Ingredients"
                          ></v-textarea>

                          <h4>Nutrition facts</h4>
                          <v-text-field
                            outlined
                            v-model="product_attribute_5.nutritionfacts_servingsize"
                            label="Serving size"
                          ></v-text-field>

                          <v-text-field
                            outlined
                            v-model="product_attribute_5.nutritionfacts_calories"
                            label="Calories"
                          ></v-text-field>

                          <v-text-field
                            outlined
                            v-model="product_attribute_5.nutritionfacts_dailyvalue"
                            label="Daily value"
                          ></v-text-field>
                        </v-col>
                        <v-col cols="3">
                          <div class="header">รายละเอียด ภาษาไทย</div><br />
                          <v-textarea
                            outlined
                            v-model="product_attribute_5.origin_th"
                            label="Origin"
                          ></v-textarea>

                          <v-textarea
                            outlined
                            v-model="product_attribute_5.description_th"
                            label="Description"
                          ></v-textarea>

                          <v-textarea
                            outlined
                            v-model="product_attribute_5.ingredients_th"
                            label="Ingredients"
                          ></v-textarea>

                          <h4>Nutrition facts</h4>
                          <v-text-field
                            outlined
                            v-model="product_attribute_5.nutritionfacts_servingsize_th"
                            label="Serving size"
                          ></v-text-field>

                          <v-text-field
                            outlined
                            v-model="product_attribute_5.nutritionfacts_calories_th"
                            label="Calories"
                          ></v-text-field>

                          <v-text-field
                            outlined
                            v-model="product_attribute_5.nutritionfacts_dailyvalue_th"
                            label="Daily value"
                          ></v-text-field>
                        </v-col>
                        <v-col cols="3">
                          <div class="header">รายละเอียด ภาษารัสเซีย</div><br />
                          <v-textarea
                            outlined
                            v-model="product_attribute_5.origin_ru"
                            label="Origin"
                          ></v-textarea>

                          <v-textarea
                            outlined
                            v-model="product_attribute_5.description_ru"
                            label="Description"
                          ></v-textarea>

                          <v-textarea
                            outlined
                            v-model="product_attribute_5.ingredients_ru"
                            label="Ingredients"
                          ></v-textarea>

                          <h4>Nutrition facts</h4>
                          <v-text-field
                            outlined
                            v-model="product_attribute_5.nutritionfacts_servingsize_ru"
                            label="Serving size"
                          ></v-text-field>

                          <v-text-field
                            outlined
                            v-model="product_attribute_5.nutritionfacts_calories_ru"
                            label="Calories"
                          ></v-text-field>

                          <v-text-field
                            outlined
                            v-model="product_attribute_5.nutritionfacts_dailyvalue_ru"
                            label="Daily value"
                          ></v-text-field>
                        </v-col>
                        <v-col cols="3">
                          <div class="header">รายละเอียด ภาษาจีน</div><br />
                          <v-textarea
                            outlined
                            v-model="product_attribute_5.origin_cn"
                            label="Origin"
                          ></v-textarea>

                          <v-textarea
                            outlined
                            v-model="product_attribute_5.description_cn"
                            label="Description"
                          ></v-textarea>

                          <v-textarea
                            outlined
                            v-model="product_attribute_5.ingredients_cn"
                            label="Ingredients"
                          ></v-textarea>

                          <h4>Nutrition facts</h4>
                          <v-text-field
                            outlined
                            v-model="product_attribute_5.nutritionfacts_servingsize_cn"
                            label="Serving size"
                          ></v-text-field>

                          <v-text-field
                            outlined
                            v-model="product_attribute_5.nutritionfacts_calories_cn"
                            label="Calories"
                          ></v-text-field>

                          <v-text-field
                            outlined
                            v-model="product_attribute_5.nutritionfacts_dailyvalue_cn"
                            label="Daily value"
                          ></v-text-field>
                        </v-col>
                      </v-row>
                    </v-card>
                  </v-tab-item>
                </v-tabs-items>
              </v-card>
            </template>
            <br />

            <h2>Select an images (เลือกได้มากกว่า 1 รูป)</h2>

            <!-- <v-btn color="primary" @click="removeImageS3">
              <v-icon left>delete</v-icon>
              Clear All
            </v-btn>
            <v-divider class="mx-4" inset vertical></v-divider> -->

            <input type="file" accept=".png,.jpg,.jpeg,.bmp,.webp" multiple @change="onFileSelected">

            <!-- <br />
            <template>
              <div class="row">
                <div class="col-12">
                  <table class="table table-striped" width="100%">
                    <thead class="thead-dark" align-center>
                      <tr>
                        <th scope="col" style="text-align: left" width="90%">Image</th>
                        <th scope="col" style="text-align: left" width="10%">Delete</th>
                      </tr>
                    </thead>
                    <draggable v-model="imageFiles" tag="tbody">
                      <tr v-for="imageFile in imageFiles" :key="imageFile">
                        <td width="90%">
                            <v-img
                              contain
                              class="ma-2"
                              :eager="true"
                              :src="imageFile"
                              lazy-src="@/assets/noimage.png"
                              aspect-ratio="1"
                              min-width="300"
                              max-width="300"
                              max-height="100"
                            >
                              <template v-slot:placeholder>
                                <v-row
                                  class="fill-height ma-0"
                                  align="center"
                                  justify="center"
                                >
                                  <v-progress-circular v-show="loading"
                                    indeterminate
                                    color="grey lighten-5"
                                  ></v-progress-circular>
                                </v-row>
                              </template>
                            </v-img>
                        </td>
                        <td width="10%">
                          <v-icon @click="removeImage(imageFile)">
                            delete
                          </v-icon>
                        </td>
                      </tr>
                    </draggable>
                  </table>
                </div>
              </div>
            </template> -->

            <!-- New -->
            <br />
            <template>
              <div class="row">
                <div class="col-12">
                  <table class="table table-striped" width="100%">
                    <thead class="thead-dark" align-center>
                      <tr>
                        <th scope="col" style="text-align: left" width="90%">Image</th>
                        <th scope="col" style="text-align: left" width="10%">Delete</th>
                      </tr>
                    </thead>
                    <draggable v-model="product_attribute_images" tag="tbody">
                      <tr v-for="item in product_attribute_images" :key="item.imageFile">
                        <td width="90%">
                            <v-img
                              contain
                              class="ma-2"
                              :eager="true"
                              :src="item.imageFile"
                              lazy-src="@/assets/noimage.png"
                              aspect-ratio="1"
                              min-width="300"
                              max-width="300"
                              max-height="100"
                            >
                              <template v-slot:placeholder>
                                <v-row
                                  class="fill-height ma-0"
                                  align="center"
                                  justify="center"
                                >
                                  <v-progress-circular v-show="loading"
                                    indeterminate
                                    color="grey lighten-5"
                                  ></v-progress-circular>
                                </v-row>
                              </template>
                            </v-img>
                        </td>
                        <td width="10%">
                          <v-icon @click="removeImage(item)">
                            delete
                          </v-icon>
                        </td>
                      </tr>
                    </draggable>
                  </table>
                </div>
              </div>
            </template>

            <!-- <br />
            <v-divider></v-divider> -->
            <br />
            <template>
              <v-row class="ma-0">
                <h2>Related Products</h2>
                <v-divider class="mx-4" inset vertical></v-divider>
                <v-btn color="primary" @click.stop="showProductSearch=true">
                  <v-icon left>add</v-icon>
                  Add Product
                </v-btn>  
                <ProductSearch v-model="showProductSearch" :onSelect="onSelect" />

                <v-btn color="primary" @click="clearProduct">
                  <v-icon left>delete</v-icon>
                  Clear All
                </v-btn>

                <v-spacer></v-spacer>
                <v-tooltip bottom color="pink darken-4">
                  <template v-slot:activator="{ on, attrs }">
                    <h3 class="mr-2" v-bind="attrs" v-on="on">Import Excel (XLSX)</h3>
                  </template>
                  <span>ใส่รหัสสินค้า 1 column ต้องมี column header ชื่อ prcode</span>
                  </v-tooltip>
                <input type="file" accept=".xlsx" @change="onFileImportSelected" ref="importFile">
                <!-- <v-spacer></v-spacer> -->
              </v-row>
            </template>

            <br />
            <template>
              <div class="row">
                <div class="col-12">
                  <table class="table table-striped" width="100%">
                    <thead class="thead-dark" align-center>
                      <tr>
                        <th scope="col" style="text-align: left">Image</th>
                        <th scope="col" style="text-align: left">(C) Pr Code</th>
                        <th scope="col" style="text-align: left">Pr Name</th>
                        <th scope="col" style="text-align: left">Online</th>
                        <!-- <th scope="col" style="text-align: left">Enabled</th> -->
                        <th scope="col" style="text-align: left">Delete</th>
                      </tr>
                    </thead>
                    <draggable v-model="mProductArray" tag="tbody">
                      <tr v-for="item in mProductArray" :key="item.cprcode">
                        <td>
                            <v-img
                              contain
                              class="ma-2"
                              :eager="true"
                              :src="item.image"
                              lazy-src="@/assets/noimage.png"
                              aspect-ratio="1"
                              min-width="80"
                              max-width="80"
                              max-height="80"
                            >
                              <template v-slot:placeholder>
                                <v-row
                                  class="fill-height ma-0"
                                  align="center"
                                  justify="center"
                                >
                                  <v-progress-circular v-show="loading"
                                    indeterminate
                                    color="grey lighten-5"
                                  ></v-progress-circular>
                                </v-row>
                              </template>
                            </v-img>
                        </td>
                        <td scope="row">{{ item.cprcode }}</td>
                        <td>{{ item.hema_name_en }}</td>
                        <td>
                          <v-checkbox
                            readonly
                            v-model="item.master_online"
                          ></v-checkbox>
                        </td>
                        <!-- <td>
                          <v-checkbox
                            readonly
                            v-model="item.enabled"
                          ></v-checkbox>
                        </td> -->
                        <td>
                          <v-icon @click="removeItem(item)">
                            delete
                          </v-icon>
                        </td>
                      </tr>
                    </draggable>
                  </table>
                </div>
              </div>

              <!-- <v-dialog v-model="confirmDeleteDlg" max-width="290">
                <v-card>
                  <v-card-title primary-title>
                    Confirm Delete
                  </v-card-title>

                  <v-card-text class="body">
                    Are you sure to delete this product? You cannot restore it after
                    clicking confirm.
                  </v-card-text>

                  <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn text @click="confirmDeleteDlg = false">
                      Cancel
                    </v-btn>

                    <v-btn color="error" text @click="confirmDelete">
                      Confirm
                    </v-btn>
                  </v-card-actions>
                </v-card>
              </v-dialog> -->
            </template>

            <br />
            <v-divider></v-divider>
            <br />

            <v-row>
              <v-spacer></v-spacer>
              <v-btn class="mr-4" @click="cancel">
                Cancel
              </v-btn>

              <v-btn color="primary" type="submit" v-if="userCode != '9a0064aa-8c44-4597-b60f-7dc8dcb8c532'">
                Confirm
              </v-btn>
            </v-row>

          </v-form>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { productAttributeBucketName } from "@/services/constants";
import axios from "axios";
import XLSX from 'xlsx';
import api from "@/services/lambdaApi";
import draggable from 'vuedraggable'
import ProductSearch from "@/components/product/ProductSearch"

export default {
  name: "product-edit",
  components:{
    draggable,
    ProductSearch
  },
  data: () => ({
    loading: false,
    product: {
      cprcode: 0,
      iprcode: 0,
      hema_name_en: "",
      hema_name_th: "",
      hema_name_ru: "",
      hema_name_cn: "",
      online_category_l1_en: "",
      online_category_l2_en: "",
      online_category_l3_en: "",
      pr_country_en: "",
      hema_brand_en: "",
      hema_sizedesc: "",
      avail_store: [],
      portion_size: "",
      psqty: 0,
      warehouse: 0,
      consign_inv: 0,
      // enabled: false,
      // preorder_delivery_type: "",
      // preorder_fix_date: "",
      // preorder_relative_day: 0,
      // product_detail_description: "",
      meta_title: "",
      meta_keywords: "",
      meta_description: "",
      height: 0.0,
      width: 0.0,
      depth: 0.0,
      weight: 0.0,
      // priority_score: 0.0,
      product_attribute_id: 1,
      product_attribute: {},
      product_attribute_images: [],
      related_products: [],
      master_online: false,
      pr_suref3: "",
      image: ""
    },
    product_attribute_images: [],
    imageFiles: [], //string
    imageUploads: [], //image
    //avail_store: ['1000','1003','1015','1016','1021','1022','1023','1026','1032','1038','1039','1040','1041','1047','1048','1049'],
    preorder: ['fixed','relative'],
    categoryLv1: [],
    categoryLv1Select: null,
    categoryLv2: [],
    categoryLv2Select: null,
    categoryLv3: [],
    categoryLv3Select: null,

    category_lv2: [],
    category_lv3: [],

    date: new Date().toISOString().substr(0, 10),
    memu1: false,
    tab: null,
    avail_store: [
      // { store: "1000", visibility: false},
      // { store: "1003", visibility: false},
      // { store: "1015", visibility: false},
      // { store: "1016", visibility: false},
      // { store: "1021", visibility: false},
      // { store: "1022", visibility: false},
      // { store: "1023", visibility: false},
      // { store: "1026", visibility: false},
      // { store: "1032", visibility: false},
      // { store: "1038", visibility: false},
      // { store: "1039", visibility: false},
      // { store: "1040", visibility: false},
      // { store: "1041", visibility: false},
      // { store: "1047", visibility: false},
      // { store: "1048", visibility: false},
      // { store: "1049", visibility: false}
    ],
    // Alcohol
    product_attribute_1: {
      //--EN
      origin: "",
      description: "",
      grape: "",
      type: "",
      style: "",
      alcohol_vol: "",
      food_paring: "",
      //-- TH
      origin_th: "",
      description_th: "",
      grape_th: "",
      type_th: "",
      style_th: "",
      alcohol_vol_th: "",
      food_paring_th: "",
      //-- RU
      origin_ru: "",
      description_ru: "",
      grape_ru: "",
      type_ru: "",
      style_ru: "",
      alcohol_vol_ru: "",
      food_paring_ru: "",
      //-- CN
      origin_cn: "",
      description_cn: "",
      grape_cn: "",
      type_cn: "",
      style_cn: "",
      alcohol_vol_cn: "",
      food_paring_cn: ""
    },
    // Butcher
    product_attribute_2: {
      //-- EN
      origin: "",
      breed: "",
      feed: "",
      description: "",
      // beef_part: []  // รูป
      //-- TH
      origin_th: "",
      breed_th: "",
      feed_th: "",
      description_th: "",
      //-- RU
      origin_ru: "",
      breed_ru: "",
      feed_ru: "",
      description_ru: "",
      //-- CN
      origin_cn: "",
      breed_cn: "",
      feed_cn: "",
      description_cn: ""
    },
    // Fruit & Veg Toy
    product_attribute_3: {
      //-- EN
      origin: "",
      description: "",
      //-- TH
      origin_th: "",
      description_th: "",
      //-- RU
      origin_ru: "",
      description_ru: "",
      //-- CN
      origin_cn: "",
      description_cn: ""
    },
    // HBA & Household
    product_attribute_4: {
      //-- EN
      origin: "",
      description: "",
      ingredients: "",
      //-- TH
      origin_th: "",
      description_th: "",
      ingredients_th: "",
      //-- RU
      origin_ru: "",
      description_ru: "",
      ingredients_ru: "",
      //-- CN
      origin_cn: "",
      description_cn: "",
      ingredients_cn: ""
    },
    // Others
    product_attribute_5: {
      //-- EN
      origin: "",
      description: "",
      ingredients: "",
      nutritionfacts_servingsize: "",
      nutritionfacts_calories: "",
      nutritionfacts_dailyvalue: "",
      // nutritionFacts: {
      //   "servingSize": "",
      //   "calories": "",
      //   "dailyValue": ""
      // }
      //-- TH
      origin_th: "",
      description_th: "",
      ingredients_th: "",
      nutritionfacts_servingsize_th: "",
      nutritionfacts_calories_th: "",
      nutritionfacts_dailyvalue_th: "",
      //-- RU
      origin_ru: "",
      description_ru: "",
      ingredients_ru: "",
      nutritionfacts_servingsize_ru: "",
      nutritionfacts_calories_ru: "",
      nutritionfacts_dailyvalue_ru: "",
      //-- CN
      origin_cn: "",
      description_cn: "",
      ingredients_cn: "",
      nutritionfacts_servingsize_cn: "",
      nutritionfacts_calories_cn: "",
      nutritionfacts_dailyvalue_cn: ""
    },
    allLabel: "ทั้งหมด",
    cprcode: 0,
    dragging: false,
    showProductSearch: false,
    confirmDeleteDlg: false,
    mProductArray: [],
    headers: [
      {
        text: "",
        align: "left",
        sortable: false
      },
      {
        text: "Image",
        align: "left",
        sortable: false,
        value: "image"
      },
      {
        text: "(C) Pr Ccode",
        align: "left",
        sortable: false,
        value: "cprcode"
      },
      {
        text: "Pr Name",
        align: "left",
        sortable: false,
        value: "hema_name_en"
      },
      {
        text: "Action",
        align: "left",
        sortable: false,
        value: "action"
      },
    ]
  }),
  async mounted() {
    try {
      this.loading = true;
      let cprcode = parseInt(this.$route.params.id);
      let data = { cprcode: cprcode };
      let result = await api.getProductByCprcode(data);
      this.product = result.data;

      this.tab = "tab-".concat(this.product.product_attribute_id ?? 1);
      if (this.tab == "tab-1")
        this.product_attribute_1 = this.product.product_attribute
      else
      if (this.tab == "tab-2")
        this.product_attribute_2 = this.product.product_attribute
      else
      if (this.tab == "tab-3")
        this.product_attribute_3 = this.product.product_attribute
      else
      if (this.tab == "tab-4")
        this.product_attribute_4 = this.product.product_attribute
      else
      if (this.tab == "tab-5")
        this.product_attribute_5 = this.product.product_attribute

      this.mProductArray = this.product.related_products;

      this.imageFiles = this.product.product_attribute_images;

      if (this.product.product_attribute_images != null) {
        this.product.product_attribute_images.forEach(imageFile => {
          this.product_attribute_images.push(
            {
              imageFile: imageFile,
              imageUpload: null
            })
        });
      }

      var filter = { fileName: "online_category_l1.json" };
      let categoryLv1 = await api.getS3Json(filter);
      this.categoryLv1 = categoryLv1.data;

      // console.log('--- categoryLv1 ---', JSON.stringify(this.categoryLv1));

      var mCategory = this.categoryLv1.find(d => d.online_category_l1_en === this.product.online_category_l1_en);
      if (mCategory) {
        this.categoryLv1Select = mCategory;
      }

      filter = { fileName: "online_category_l2.json" };
      let categoryLv2 = await api.getS3Json(filter);
      this.categoryLv2 = categoryLv2.data;

      // console.log('--- categoryLv2 ---', JSON.stringify(this.categoryLv2));

      mCategory = this.categoryLv2.find(d => d.online_category_l2_en === this.product.online_category_l2_en);
      if (mCategory) {
        this.categoryLv2Select = mCategory;
      }

      filter = { fileName: "online_category_l3.json" };
      let categoryLv3 = await api.getS3Json(filter);
      this.categoryLv3 = categoryLv3.data;

      // console.log('--- categoryLv3 ---', JSON.stringify(this.categoryLv3));

      mCategory = this.categoryLv3.find(d => d.online_category_l3_en === this.product.online_category_l3_en);
      if (mCategory) {
        this.categoryLv3Select = mCategory;
      }

      filter = { fileName: "online_stores.json" };
      let avail_store = await api.getS3Json(filter);
      this.avail_store = avail_store.data;
      this.setAvailStore();

      // console.log(JSON.stringify('--- product_attribute_images ---', this.imageFiles));

    // } catch (error) {
    }
    finally {
      this.loading = false;
    }
  },

  methods: {
    setAvailStore() {
        let availStores = this.product.avail_store;
        availStores.forEach(item => {
          let mItem = this.avail_store.find(d => d.storeCode === item.store);
          if (mItem) {
            // console.log('--- mItem ---', mItem);
            mItem.range = true;
            mItem.visibility = item.visibility;
          }
        });
    },
    clearProduct() {
      this.mProductArray = []
    },
    onFileImportSelected(e) {
      try {
        this.loading = true;
        var files = e.target.files || e.dataTransfer.files;
        if (files.length == 0)
          return;
        this.previewFiles(files[0]);
      // } catch (error) {
      }
      finally {
        this.$refs.importFile.value = "";
        setTimeout(() => {
          this.loading = false;
        }, 1000);
      }
    },
    previewFiles(file) { // detect file event
        var reader = new FileReader();
        var vm = this;
        reader.onload = async function(e) {
            var data = new Uint8Array(e.target.result);
            var workbook = XLSX.read(data, { type: "array" });
            let sheetName = workbook.SheetNames[0];
            /* DO SOMETHING WITH workbook HERE */
            // console.log(workbook);
            let worksheet = workbook.Sheets[sheetName];
            // console.log(XLSX.utils.sheet_to_json(worksheet));
            let prcodes = XLSX.utils.sheet_to_json(worksheet);
            // console.log('-- prcodes --', prcodes);
            let prcodeList = [];
            prcodes.forEach(item => {
              prcodeList.push(parseInt(item.prcode ?? 0));
            });
            if (prcodeList && prcodeList.length>0) {
              // console.log('-- prcodeList --', prcodeList);
              let result = await api.getProductByPrcodeList(prcodeList);
              let productList = result.data;
              // console.log('-- productList --', productList);
              productList.forEach(product => {
                // console.log('-- product --', product);
                vm.onSelect(product);
              });
            }
        };
        reader.readAsArrayBuffer(file);
    },
    onFileSelected(e) {
      var files = e.target.files || e.dataTransfer.files;

      // alert(files.length)

      if (files.length == 0)
        return;

      // this.imageFiles = [];
      // this.imageUploads = [];

      Array.from(files).forEach(file => { this.createImage(file); });

      // files.forEach(file => {
      //   this.createImage(file);
      // });
    },
    createImage(file) {
      // var image = new Image();
      var reader = new FileReader();
      var vm = this;

      reader.onload = (e) => {
        vm.imageFiles.push(e.target.result);
        vm.imageUploads.push(file)
        vm.product_attribute_images.push(
          {
            imageFile: e.target.result,
            imageUpload: file
          })
      };
      reader.readAsDataURL(file);
    },
    removeImage: function (item) {
      // this.imageFiles = [];
      // this.imageUploads = [];
      // let index = this.imageFiles.indexOf(imageFile);
      // if (index >= 0) {
      //   this.imageFiles.splice(index, 1);
      //   this.imageUploads.splice(index, 1);
      // }

      let mItem = this.product_attribute_images.find(d => d.imageFile === item.imageFile);
      if (mItem) {
        let index = this.product_attribute_images.indexOf(mItem);
        if (index >= 0) {
          this.product_attribute_images.splice(index, 1)
        }
      }
    },
    removeItem: function (item) {
      let mProduct = this.mProductArray.find(d => d.cprcode === item.cprcode);
      if (mProduct) {
        let index = this.mProductArray.indexOf(mProduct);
        if (index >= 0) {
          this.mProductArray.splice(index, 1)
        }
      }
    },
    
    // deleteItem(item) {
    //   this.cprcode = item.cprcode;
    //   this.confirmDeleteDlg = true;
    // },
    // async confirmDelete() {
    //   let mProduct = this.mProductArray.find(d => d.cprcode === this.cprcode);
    //   if (mProduct) {
    //     let index = this.mProductArray.indexOf(mProduct);
    //     if (index >= 0) {
    //       this.mProductArray.splice(index, 1)
    //     }
    //   }
    //   this.confirmDeleteDlg = false;
    // },
    cancel() {
      this.$router.back();
    },
    zoneAllClick() {
      let visibility;
      if (this.allLabel == "ทั้งหมด") {
        this.allLabel = "เคลียร์";
        visibility = true;
      }
      else {
        this.allLabel = "ทั้งหมด";
        visibility = false;
      }
      let availStores = this.avail_store;
      availStores.forEach(item => {
        item.range = visibility;
        item.visibility = visibility;
      });
    },
    selectStores(storeSelect) {
      let availStores = this.avail_store;
      availStores.forEach(item => {
        let index = storeSelect.indexOf(item.storeCode);
        item.range = index >= 0 ? true : false;
        item.visibility = item.range;
      });
    },
    zone1Click() {
      // this.selectStores(['1000','1003','1022','1023','1026','1038','1041','1047','1049']);
      this.selectStores(['1000','1003','1005','1006','1007','1011',,'1014',
      '1017','1019','1020','1022','1023','1024','1026','1030','1033','1034','1036',
      '1038','1041','1044','1046','1047','1049','1051','1052','1053','1054','1055']);
    },
    zone2Click() {
      this.selectStores(['1021','1032','1039','1050','1056','1057']);
    },
    zone3Click() {
      this.selectStores(['1016','1040']);
    },
    zone4Click() {
      this.selectStores(['1015','1048']);
    },
    // zoneAllClick() {
    //   if (this.allLabel == "ทั้งหมด") {
    //     this.allLabel = "เคลียร์";
    //     this.product.avail_store = ['1000','1003','1022','1023','1026','1038','1041','1047','1049','1021','1032','1039','1016','1040','1015','1048']
    //   }
    //   else {
    //     this.allLabel = "ทั้งหมด";
    //     this.product.avail_store = []
    //   }
    // },
    // zone1Click() {
    //   this.product.avail_store = ['1000','1003','1022','1023','1026','1038','1041','1047','1049']
    // },
    // zone2Click() {
    //   this.product.avail_store = ['1021','1032','1039']
    // },
    // zone3Click() {
    //   this.product.avail_store = ['1016','1040']
    // },
    // zone4Click() {
    //   this.product.avail_store = ['1015','1048']
    // },
    onSelect(item) {
      //console.log(JSON.stringify(item));
      if (item != null) {
        let mProduct = this.mProductArray.find(d => d.cprcode === item.cprcode);
        if (!mProduct) {
          let product = {
            "cprcode": item.cprcode,
            "hema_name_en": item.hema_name_en,
            "image": item.image,
            "master_online": item.master_online,
            // "enabled": item.enabled
          };
          this.mProductArray.push(product);
        }
      }
    },
    async submit() {
      try {
        this.loading = true;
        // await this.removeImageS3();
        // return;

        //console.log(JSON.stringify(this.product));
        // let formData = new FormData();
        // const { name, price, stock, id } = this.product;
        // formData.append("id", id);
        // formData.append("name", name);
        // formData.append("stock", stock);
        // formData.append("price", price);
        // await api.setProductByCprcode(formData);

        if (this.categoryLv1Select != null)
          this.product.online_category_l1_en = this.categoryLv1Select.online_category_l1_en;
        else
          this.product.online_category_l1_en = "";

        if (this.categoryLv2Select != null)
          this.product.online_category_l2_en = this.categoryLv2Select.online_category_l2_en;
        else
          this.product.online_category_l2_en = "";

        if (this.categoryLv3Select != null)
          this.product.online_category_l3_en = this.categoryLv3Select.online_category_l3_en;
        else
          this.product.online_category_l3_en = "";

        this.product.psqty = parseInt(this.product.psqty ?? 0);
        this.product.warehouse = parseInt(this.product.warehouse ?? 0);
        this.product.consign_inv = parseInt(this.product.consign_inv ?? 0);
        // this.product.preorder_relative_day = parseInt(this.product.preorder_relative_day ?? 0);
        this.product.height = parseFloat(this.product.height ?? 0);  
        this.product.width = parseFloat(this.product.width ?? 0);    
        this.product.depth = parseFloat(this.product.depth ?? 0); 
        this.product.weight = parseFloat(this.product.weight ?? 0);  
        this.product.portion_size = parseInt(this.product.portion_size ?? 0);    
        // this.product.priority_score = parseFloat(this.product.priority_score ?? 0);

        let avail_store = [];
        this.avail_store.forEach(item => {
          if (item.range) {
            // avail_store.push(item);
            avail_store.push({ store: item.storeCode, visibility: item.visibility });
          }
        });
        this.product.avail_store = avail_store;

        this.product.product_attribute_id = parseInt(this.tab.substr(this.tab.length - 1, 1) ?? 0);

        if (this.tab == "tab-1")
          this.product.product_attribute = this.product_attribute_1;
        else
        if (this.tab == "tab-2")
          this.product.product_attribute = this.product_attribute_2;
        else
        if (this.tab == "tab-3")
          this.product.product_attribute = this.product_attribute_3;
        else
        if (this.tab == "tab-4")
          this.product.product_attribute = this.product_attribute_4;
        else
        if (this.tab == "tab-5")
          this.product.product_attribute = this.product_attribute_5;

        // console.log(JSON.stringify(this.product.product_attribute));

        let related_products = [];
        this.mProductArray.forEach(item => {
          related_products.push(parseInt(item.cprcode ?? 0));
        });
        this.product.related_products = related_products;

        this.upLoadImage().then((res) => {
          // if (res) {
            setTimeout(() => {
              this.product.product_attribute_images = res;
              // console.log('--- this.product.product_attribute_images ---', this.product.product_attribute_images);
              // console.log('request', JSON.stringify(this.product));
              api.setProductByCprcode(this.product);
              console.log('--- success ---');
            }, 3000);
          // }
          // console.log('request', JSON.stringify(this.product));
          // await api.setProductByCprcode(this.product);

          // setTimeout(async() => {  await this.upLoadImage(); }, 10000);
          // setTimeout(async() => { await api.setProductByCprcode(this.product); }, 1000);
          // console.log('--- success ---');
        });

        // console.log('request', JSON.stringify(this.product));

        // // setTimeout(async() => {  await this.upLoadImage(); }, 10000);
        // setTimeout(async() => { await api.setProductByCprcode(this.product); }, 1000);
        // console.log('--- success ---');

        // console.log('result', JSON.stringify(result));

        // await api.setProductByCprcode(this.product);

        //if (response.statusCode == 200)
        // await this.upLoadImage(this.product.cprcode);

        this.$router.back();
      // } catch (error) {
      }
      finally {
        this.loading = false;
      } 
    },
    async removeImageS3() {
      return;
      try {
        this.loading = true;
        let cprcode = this.product.cprcode;
        // let key = cprcode.toString()
        let key = cprcode.toString(); // + "/" + cprcode.toString()+ "-3"
        let data = { bucket: productAttributeBucketName, key: key }
        let result = await api.presignUpPics(data);
        let presignUrls = result.data;
        console.log('--- remove presignUrls ---', presignUrls);
        if (presignUrls)
        {
            let formData = new FormData();
            formData.append("Content-Type", presignUrls.fields["Content-Type"]);
            formData.append("x-amz-security-token", presignUrls.fields["x-amz-security-token"]);
            formData.append("key", presignUrls.fields.key);
            formData.append("AWSAccessKeyId", presignUrls.fields["AWSAccessKeyId"]);
            formData.append("policy", presignUrls.fields["policy"]);
            formData.append("signature", presignUrls.fields["signature"]);
            // formData.append('file', key);
            await axios.delete(presignUrls.url,
              formData,
              // {
              //   headers: {
              //     'Content-Type': 'multipart/form-data'
              //   }
              // }
              ).then(function(){
                console.log('Remove image SUCCESS!!');
              })
              .catch(function(){
                console.log('Remove image FAILURE!!');
              });
        }
      }
      finally {
        this.loading = false;
      } 
    },
    // async uploadImageToS3(presignUrls, formData, product_attribute_images) {
    //   return await axios.post(presignUrls.url,
    //     formData,
    //     {
    //       headers: {
    //         'Content-Type': 'multipart/form-data'
    //       }
    //     }
    //     ).then(function(){
    //       let url = presignUrls.url + presignUrls.fields.key;
    //       product_attribute_images.push(url);
    //       console.log('Upload image SUCCESS!!');
    //     })
    //     .catch(function(){
    //       console.log('Upload image FAILURE!!');
    //     });
    // },
    async upLoadImage() {
      let cprcode = this.product.cprcode;
      let product_attribute_images = [];
      // console.log('--- imageUploads (count) ---', this.imageUploads.length);
      if (this.product_attribute_images != null) {
        var count = 0;
        this.product_attribute_images.forEach(async item => {
          if (item.imageUpload != null) {
            let key = cprcode.toString() + "/" + cprcode.toString()+ "-" + (++count).toString()
            let data = { bucket: productAttributeBucketName, key: key }
            let result = await api.presignUpPics(data);
            let presignUrls = result.data;
            // console.log('--- presignUrls ---', JSON.stringify(presignUrls));
            if (presignUrls)
            {
                let formData = new FormData();
                formData.append("Content-Type", presignUrls.fields["Content-Type"]);
                formData.append("x-amz-security-token", presignUrls.fields["x-amz-security-token"]);
                formData.append("key", presignUrls.fields.key);
                formData.append("AWSAccessKeyId", presignUrls.fields["AWSAccessKeyId"]);
                formData.append("policy", presignUrls.fields["policy"]);
                formData.append("signature", presignUrls.fields["signature"]);
                formData.append('file', item.imageUpload);
                await axios.post(presignUrls.url,
                  formData,
                  {
                    headers: {
                      'Content-Type': 'multipart/form-data'
                    }
                  }
                  ).then(function(){
                    let url = presignUrls.url + presignUrls.fields.key;
                    product_attribute_images.push(url);
                    console.log('Upload image SUCCESS!!');
                  })
                  .catch(function(){
                    console.log('Upload image FAILURE!!');
                  });
            }
          }
          else
            product_attribute_images.push(item.imageFile);
        });
      }
      return product_attribute_images;
      // this.product.product_attribute_images = product_attribute_images;
      // console.log('--- product_attribute_images ---', this.product.product_attribute_images);
    },
    // async upLoadImage() {
    //   let cprcode = this.product.cprcode;
    //   let product_attribute_images = [];
    //   // console.log('--- imageUploads (count) ---', this.imageUploads.length);
    //   if (this.imageUploads != null && this.imageUploads.length > 0) {
    //       var count = 0;
    //       this.imageUploads.forEach(async imageUpload => {
    //         let key = cprcode.toString() + "/" + cprcode.toString()+ "-" + (++count).toString()
    //         let data = { bucket: productAttributeBucketName, key: key }
    //         let result = await api.presignUpPics(data);
    //         let presignUrls = result.data;
    //         // console.log('--- presignUrls ---', JSON.stringify(presignUrls));
    //         if (presignUrls)
    //         {
    //             let formData = new FormData();
    //             formData.append("Content-Type", presignUrls.fields["Content-Type"]);
    //             formData.append("x-amz-security-token", presignUrls.fields["x-amz-security-token"]);
    //             formData.append("key", presignUrls.fields.key);
    //             formData.append("AWSAccessKeyId", presignUrls.fields["AWSAccessKeyId"]);
    //             formData.append("policy", presignUrls.fields["policy"]);
    //             formData.append("signature", presignUrls.fields["signature"]);
    //             formData.append('file', imageUpload);
    //             await axios.post(presignUrls.url,
    //               formData,
    //               {
    //                 headers: {
    //                   'Content-Type': 'multipart/form-data'
    //                 }
    //               }
    //               ).then(function(){
    //                 let url = presignUrls.url + presignUrls.fields.key;
    //                 product_attribute_images.push(url);
    //                 console.log('Upload image SUCCESS!!');
    //               })
    //               .catch(function(){
    //                 console.log('Upload image FAILURE!!');
    //               });
    //         }
    //       });
    //   }
    //   this.product.product_attribute_images = product_attribute_images;
    //   console.log('--- product_attribute_images ---', this.product.product_attribute_images);
    // },
  },
  computed: {
    userCode() {
      return this.$store.getters["userInfo"]["userCode"]?.toString() ?? "";
    },
    filterCategoryLv2() {
      if (this.categoryLv1Select == null)
        return []
      else {
        return this.categoryLv2.filter(o => o.online_category_l1_en == this.categoryLv1Select.online_category_l1_en)
      }
    },
    filterCategoryLv3() {
      if (this.categoryLv2Select == null)
        return []
      else {
        return this.categoryLv3.filter(o => o.online_category_l1_en == this.categoryLv2Select.online_category_l1_en && o.online_category_l2_en == this.categoryLv2Select.online_category_l2_en)
      }
    },
  }
};
</script>

<style scoped>
table {
  border-spacing: 0 0.5rem;
}
tbody tr:nth-of-type(odd) {
   background-color: rgba(0, 0, 0, .05);
}
.table_store {
   /* height: 420px; */
   height: 850px;
   overflow: auto;
}
.header {
  font-size: 18px;
  font-weight: bold;
  color: #D50000;
}
</style>