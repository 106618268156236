<template>
  <v-container v-if="loading">
    <div class="text-center">
      <v-progress-circular
        indeterminate
        :size="80"
        :width="8"
        color="primary">
      </v-progress-circular>
    </div>
  </v-container>
  <v-container fluid v-else>
    <v-row class="justify-center">
      <v-col cols="12">
        <v-card class="mx-0 pa-5" outlined>
          <v-form @submit.prevent="submit">
            <v-row>
              <v-col cols="6">
                <v-text-field
                  outlined
                  v-model="createQty"
                  label="Create Qty"
                  type="number"
                  class="inputNumber"
                ></v-text-field>

                <v-radio-group
                  v-model="voucherType"
                  row
                >
                  <v-radio
                    label="Coupon"
                    :value="0"
                  ></v-radio>
                  <v-radio
                    label="Voucher"
                    :value="1"
                  ></v-radio>
                </v-radio-group>
                <v-text-field
                  outlined
                  v-model="amount"
                  label="Amout"
                  type="number"
                  class="inputNumber"
                ></v-text-field>

                <v-text-field
                  outlined
                  v-model="remark"
                  label="Remark"
                ></v-text-field>
              </v-col>
            </v-row>

            <v-divider></v-divider>
            <br />
            <template>
              <div class="row">
                <div class="col-6">
                  <div class="table_store">
                    <table class="table table-striped" width="100%">
                      <thead class="thead-dark" align-center>
                        <tr>
                          <th scope="col" style="text-align: left">Coupon Code</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr v-for="item in mDataArray" :key="item">
                          <td>{{ item }}</td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </template>

            <br />
            <v-divider></v-divider>
            <br />
            <v-row>
              <v-btn class="mr-4" @click="cancel">
                Back
              </v-btn>

              <v-btn class="mr-4" color="primary" type="submit" v-if="userCode != '9a0064aa-8c44-4597-b60f-7dc8dcb8c532'">
                Create Voucher
              </v-btn>

              <v-btn color="primary" @click="exportClick" v-if="userCode != '9a0064aa-8c44-4597-b60f-7dc8dcb8c532'">
                Save Excel
              </v-btn>
              <v-spacer></v-spacer>
            </v-row>
          </v-form>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import XLSX from 'xlsx';
import api from "@/services/lambdaApi";

export default {
  name: "voucher-edit",
  // components:{
  // },
  data: () => ({
    loading: false,
    createQty: 1,
    voucherType: 0,
    amount: 0,
    remark: "",
    mDataArray: [],
  }),
  // async mounted() {
  //   try {
  //     this.loading = true;
  //     for (let index = 0; index < 1000; index++) {
  //       this.mDataArray.push(index+1)
  //     }
  //   // } catch (error) {
  //   }
  //   finally {
  //     this.loading = false;
  //   } 
  // },
  methods: {
    exportClick() {
      try {
        this.loading = true;
        const dataWS = XLSX.utils.json_to_sheet(this.dataExport)
        const wb = XLSX.utils.book_new()
        XLSX.utils.book_append_sheet(wb, dataWS)
        XLSX.writeFile(wb,'couponCode.xlsx')
      } catch (error) {
        alert(error)
      } finally {
        this.loading = false;
      }
    },
    cancel() {
      this.$router.back();
    },
    async submit() {
      try {
        let qty = parseInt(this.createQty ?? 0);
        let amount = parseFloat(this.amount ?? 0);
        if (qty <= 0) {
          alert("Please enter qty");
          return;
        };
        if (amount <= 0) {
          alert("Please enter amount");
          return;
        };
        if (confirm("Confirm create voucher?")) {
          this.loading = true;

          let data = { 
            qty: qty, 
            voucherType: parseInt(this.voucherType ?? 0), 
            amount: amount, 
            remark: this.remark, 
            userCode: this.userCode 
          };
          let result = await api.createVoucher(data);
          this.mDataArray = result.data;
          console.log("--- data list ---", result.data);

          // this.$router.back();
        }
      } catch (error) {
        alert(error)
      }
      finally {
        this.loading = false;
      }
    },
  },
  computed: {
    userCode() {
      return this.$store.getters["userInfo"]["userCode"]?.toString() ?? "";
    },
    dataExport() {
      if (this.mDataArray == null)
        return []
      else {
        let dataResult = [];
        this.mDataArray.forEach(item => {
          var data = {
            "couponCode": item ?? "", 
          };
          dataResult.push(data);
        });
        return dataResult;
      };
    },
    // userGroup() {
    //   return this.$store.getters["userInfo"]["userGroup"]?.toString().toLowerCase().trim() ?? "";
    // },
    // userType() {
    //   return this.$store.getters["userInfo"]["userType"]?.toString() ?? "";
    // },
    // userBrcode() {
    //   return this.$store.getters["userInfo"]["brcode"]?.toString() ?? "";
    // },
  }
};
</script>

<style scoped>
table {
  border-spacing: 0 0.5rem;
}
tbody tr:nth-of-type(odd) {
   background-color: rgba(0, 0, 0, .05);
 }
 .table_store {
   height: 200px;
   overflow: auto;
}
.inputNumber >>> input[type='number'] {
    -moz-appearance:textfield;
}
.inputNumber >>> input::-webkit-outer-spin-button,
.inputNumber >>> input::-webkit-inner-spin-button {
    -webkit-appearance: none;
}
</style>