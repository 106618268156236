<template>
    <div>
      <v-container v-if="loading">
      <div class="text-center">
        <v-progress-circular indeterminate :size="80" :width="8" color="primary">
        </v-progress-circular>
      </div>
    </v-container>
    <v-container fluid v-else>
      <v-alert
        v-model="show_success"
        dismissible
        outlined
        text
        type="success"
      >Update ข้อมูลเว็บ เรียบร้อยแล้ว</v-alert>
      <v-alert
        v-model="show_error"
        dismissible
        outlined
        text
        type="error"
      >Update ข้อมูลเว็บ ไม่สำเร็จ!!</v-alert>
      <!-- Table section -->
      <v-card>
        <v-data-table
          :search="search"
          :headers="headers"
          :items="mDataArray"
          sort-by="groupId"
          :sort-desc="true"
          :items-per-page="5"
        >
          <!-- table top section -->
          <template v-slot:top>
            <v-toolbar flat color="white">
              <v-toolbar-title>Seasonal</v-toolbar-title>
              <v-divider class="mx-4" inset vertical></v-divider>
              <v-text-field
                v-model="search"
                label="Search"
                single-line
                hide-details
              ></v-text-field>
              <v-icon color="primary" class="mr-2" @click="searchClick">
                search
              </v-icon>
              <v-divider class="mx-4" inset vertical></v-divider>
              <label>Section</label>
              <v-divider class="mx-4" inset vertical></v-divider>
              <v-text-field
                v-model="groupName"
                label=""
                single-line
                hide-details
              ></v-text-field>
              <v-icon color="primary" class="mr-2" @click="saveClick" v-if="userCode != '9a0064aa-8c44-4597-b60f-7dc8dcb8c532'">
                save
              </v-icon>
              <v-spacer></v-spacer>
              <v-btn @click="refreshGroup" color="green darken-2" dark class="mb-2">
                <v-icon left>refresh</v-icon>
                <span>Update Web</span>
              </v-btn>
              <v-divider class="mx-4" inset vertical></v-divider>
              <v-btn @click="newGroup" color="primary" dark class="mb-2">
                <v-icon left>add</v-icon>
                <span>New Group</span>
              </v-btn>
            </v-toolbar>
          </template>
  
          <!-- table tr section -->
          <template v-slot:item="{ item }">
            <tr>
              <!-- <td>
                <v-img
                  contain
                  class="ma-2"
                  :eager="true"
                  :src="item.metadata.imageUrl[0]"
                  lazy-src="@/assets/noimage.png"
                  aspect-ratio="1"
                  min-width="80"
                  max-width="80"
                  max-height="80"
                >
                  <template v-slot:placeholder>
                    <v-row
                      class="fill-height ma-0"
                      align="center"
                      justify="center"
                    >
                      <v-progress-circular
                        v-show="loading"
                        indeterminate
                        color="grey lighten-5"
                      ></v-progress-circular>
                    </v-row>
                  </template>
                </v-img>
              </td> -->
              <td>{{ item.groupId }}</td>
              <td>{{ item.groupName }}</td>
              <td>{{ item.metadata.slotIndex }}</td>
              <td>{{ item.metadata.startDate }}</td>
              <td>{{ item.metadata.endDate }}</td>
              <td>
                <v-checkbox readonly v-model="item.enabled"></v-checkbox>
              </td>
              <td>
                <v-icon class="mr-2" @click="editItem(item)">
                  edit
                </v-icon>
              </td>
            </tr>
          </template>
        </v-data-table>
      </v-card>
    </v-container>
    </div>
  </template>
  
  <script>
  import surveyService from "@/services/surveyService";
  import api from "@/services/lambdaApi";
  
  export default {
    name: "seasonal2",
    data() {
      return {
        loading: false,
        search: "",
        show_success: false,
        show_error: false,
        enabled: true,
        mDataArray: [],
        // mainGroup: {},
        groupName: "",
        headers: [
          // {
          //   text: "Image",
          //   align: "left",
          //   sortable: false,
          //   value: "objectUrl",
          // },
          { text: "Group Id", value: "groupId" },
          { text: "Group Name", value: "groupName" },
          { text: "Slot Index", value: "metadata.slotIndex" },
          { text: "Start Datetime", value: "metadata.startDate" },
          { text: "End Datetime", value: "metadata.endDate" },
          {
            text: "Enabled",
            align: "left",
            // sortable: false,
            value: "enabled",
          },
          {
            text: "Action",
            align: "left",
            sortable: false,
            value: "action",
          },
        ],
      };
    },
    // components: {
    // },
    async mounted() {
        this.loadSubCategory();
    },
    methods: {
      searchClick() {
        this.loadSubCategory();
      },
      async saveClick() {
        try {
          let sectionName = (this.groupName ?? "").trim();
          if (sectionName == "") {
            alert("Please enter section.")
            return;
          }
          let data = { groupId: 7000000001, groupName: sectionName, updateType: "rename_group" };
          let response = await api.updateGroup(data);
          if (response.data.statusCode == 200) {
            let firstPage = await surveyService.getAppFirstPage();
            if (firstPage?.Status ?? "" == "success") {
              let carouselCategoryList = firstPage?.data?.carouselCategoryList ?? [];
              if (carouselCategoryList != null && carouselCategoryList.length > 0) {
                // alert(carouselCategoryList);
                carouselCategoryList[0] = sectionName;
                let result = await surveyService.saveAppFirstPage(carouselCategoryList);
                // alert(result?.Status);
              }
            }
            alert("Save Success.");
            return;
          }
        } catch (error) {
          alert(error)
        }
      },
      editItem(item) {
        this.$router.push(`/seasonal2/edit/${item.groupId}`);
      },
      async refreshGroup() {
        try {
          this.loading = true;
          let response = await api.refreshGroup({});
          console.log("--- refreshGroup ---", response.data);
          if (response.data.statusCode == 200) {
            this.show_error = false;
            this.show_success = true;
          } else {
            this.show_error = true;
            this.show_success = false;
          }
          // } catch (error) {
        } finally {
          this.loading = false;
        }
      },
      newGroup() {
        this.$router.push("/seasonal2/edit/0");
      },
      async loadSubCategory() {
        try {
          this.loading = true;

          let data = { groupId: 7000000001 }
          let mainGroup = await api.getGroupById(data);
          this.groupName = mainGroup?.data["groupName"] ?? "";

          let group = { groupId: 7000000001 }
          let category = await api.getGroupList(group);
          this.mDataArray = category.data;
          console.log("--- sub category ---", category.data);
          // } catch (error) {
        } finally {
          this.loading = false;
        }
      },
    },
  computed: {
    userCode() {
      return this.$store.getters["userInfo"]["userCode"]?.toString() ?? "";
    },
  }
  };
  </script>
  
  <style scoped>
  .v-progress-circular {
    margin: 1rem;
  }
  v-data-table table {
    border-spacing: 0 0.5rem;
  }
  tbody tr:nth-of-type(odd) {
    background-color: rgba(0, 0, 0, 0.05);
  }
  </style>