<template>
  <v-container v-if="loading">
    <div class="text-center">
      <v-progress-circular
        indeterminate
        :size="80"
        :width="8"
        color="primary">
      </v-progress-circular>
    </div>
  </v-container>
  <v-container fluid v-else>
    <v-row class="justify-center">
      <v-col cols="12">
        <v-card class="mx-10 pa-5" outlined>
          <v-form @submit.prevent="submit">
            <template>
              <v-row class="ma-0">
                <v-spacer></v-spacer>
                <h1>Import Product Information</h1>
                <v-spacer></v-spacer>
              </v-row>
            </template>

            <v-divider></v-divider>
            <br />
            <template>
              <v-row class="ma-0">
                <h3 class="mr-3">Import Excel (XLSX)</h3>
                <input type="file" accept=".xlsx" @change="onFileImportSelected" ref="importFile">
                <v-spacer></v-spacer>
                <v-btn color="primary" @click="clearData">
                  <v-icon left>delete</v-icon>
                  Clear All
                </v-btn>
              </v-row>
            </template>

            <br />
            <v-divider></v-divider>

            <template>
              <div class="row">
                <div class="mt-3 col-12">
                  <table class="table table-striped" width="100%">
                    <thead class="head-dark" align-center>
                      <tr>
                        <th scope="col" style="text-align: left">Image</th>
                        <th scope="col" style="text-align: left">(C) Pr Code</th>
                        <th scope="col" style="text-align: left">Pr Name (EN)</th>
                        <th scope="col" style="text-align: left">Pr Name (TH)</th>
                        <th scope="col" style="text-align: left">Online</th>
                        <th scope="col" style="text-align: left">Action</th>
                      </tr>
                    </thead>
                    <tbody>
                      <template v-for="item in mDataArray">
                        <tr>
                          <td>
                              <v-img
                                contain
                                class="ma-2"
                                :eager="true"
                                :src="item.image"
                                lazy-src="@/assets/noimage.png"
                                aspect-ratio="1"
                                min-width="80"
                                max-width="80"
                                max-height="80"
                              >
                                <template v-slot:placeholder>
                                  <v-row
                                    class="fill-height ma-0"
                                    align-center
                                    justify="center"
                                  >
                                    <v-progress-circular v-show="loading"
                                      indeterminate
                                      color="grey lighten-5"
                                    ></v-progress-circular>
                                  </v-row>
                                </template>
                              </v-img>
                          </td>
                          <td>{{ item.cprcode }}</td>
                          <td>{{ item.hema_name_en }}</td>
                          <td>{{ item.hema_name_th }}</td>
                          <td>
                            <v-checkbox
                              readonly
                              v-model="item.master_online"
                            ></v-checkbox>
                          </td>
                          <td>
                            <v-icon @click="removeItem(item)">
                              delete
                            </v-icon>
                          </td>
                        </tr>

                        <tr><td colspan="6" width="100%">
                          <table class="table table-striped" width="100%">
                            <tr>
                              <td width="50%" style="vertical-align: top;">
                                <table class="table table-striped" width="100%">
                                  <tr>
                                    <td class="subject" width="30%">Category Lv1 : </td>
                                    <td>{{ item.online_category_l1_en }}</td>
                                  </tr>
                                  <tr>
                                    <td class="subject" width="30%">Category Lv2 : </td>
                                    <td>{{ item.online_category_l2_en }}</td>
                                  </tr>
                                  <tr>
                                    <td class="subject" width="30%">Category Lv3 : </td>
                                    <td>{{ item.online_category_l3_en }}</td>
                                  </tr>
                                  <tr>
                                    <td class="subject" width="30%">Country : </td>
                                    <td>{{ item.pr_country_en }}</td>
                                  </tr>
                                  <tr>
                                    <td class="subject" width="30%">Brand : </td>
                                    <td>{{ item.hema_brand_en }}</td>
                                  </tr>
                                  <tr>
                                    <td class="subject" width="30%">Size Desc : </td>
                                    <td>{{ item.hema_sizedesc }}</td>
                                  </tr>
                                  <tr>
                                    <td class="subject" width="30%">Meta Title : </td>
                                    <td>{{ item.meta_title }}</td>
                                  </tr>
                                  <tr>
                                    <td class="subject" width="30%">Meta Keywords : </td>
                                    <td>{{ item.meta_keywords }}</td>
                                  </tr>
                                  <tr>
                                    <td class="subject" width="30%">Meta Description : </td>
                                    <td>{{ item.meta_description }}</td>
                                  </tr>
                                </table>
                              </td>
                              <td width="50%" style="vertical-align: top;">
                                <table class="table table-striped" width="100%">
                                  <tr>
                                    <td class="subject" width="40%">Consign Inventory : </td>
                                    <td>{{ item.consign_inv }}</td>
                                  </tr>
                                  <tr>
                                    <td class="subject" width="40%">Warehouse : </td>
                                    <td>{{ item.warehouse }}</td>
                                  </tr>
                                  <tr>
                                    <td class="subject" width="40%">Pack Size Qty: </td>
                                    <td>{{ item.psqty }}</td>
                                  </tr>
                                  <!-- <tr>
                                    <td class="subject" width="40%">Priority Score : </td>
                                    <td>{{ item.priority_score }}</td>
                                  </tr> -->
                                  <tr>
                                    <td class="subject" width="40%">Height : </td>
                                    <td>{{ item.height }}</td>
                                  </tr>
                                  <tr>
                                    <td class="subject" width="40%">Width : </td>
                                    <td>{{ item.width }}</td>
                                  </tr>
                                  <tr>
                                    <td class="subject" width="40%">Depth : </td>
                                    <td>{{ item.depth }}</td>
                                  </tr>
                                  <tr>
                                    <td class="subject" width="40%">Weight : </td>
                                    <td>{{ item.weight }}</td>
                                  </tr>
                                  <tr>
                                    <td class="subject" width="40%">Portion : </td>
                                    <td>{{ item.portion_size }}</td>
                                  </tr>
                                  <!-- <tr>
                                    <td class="subject" width="40%">Preorder Delivery Type : </td>
                                    <td>{{ item.preorder_delivery_type }}</td>
                                  </tr>
                                  <tr>
                                    <td class="subject" width="40%">Preorder Delivery Date : </td>
                                    <td>{{ item.preorder_fix_date }}</td>
                                  </tr>
                                  <tr>
                                    <td class="subject" width="40%">Preorder Relative Day : </td>
                                    <td>{{ item.preorder_relative_day }}</td>
                                  </tr> -->
                                </table>
                              </td>
                            </tr>
                          </table>
                        </td></tr>

                        <tr><td colspan="6" width="100%">
                          <h3>Avaliable Store :</h3>
                          <div class="table_store">
                            <table class="table table-striped" width="100%">
                              <tr>
                                <td class="subject" width="20%">Store</td>
                                <td class="subject" width="20%">Visibility</td>
                                <td class="subject" width="60%">Action</td>
                              </tr>
                              <template v-for="data in item.avail_store">
                                <tr>
                                  <td>{{ data.store }}</td>
                                  <td>
                                    <v-checkbox
                                      height="1"
                                      readonly
                                      v-model="data.visibility"
                                    ></v-checkbox>
                                  </td>
                                  <td>
                                    <v-icon @click="removeItemStore(item, data)">
                                      delete
                                    </v-icon>
                                  </td>
                                </tr>
                              </template>
                            </table>
                          </div>
                        </td></tr>

                        <tr><td colspan="6" width="100%">
                        <v-divider color="#0D47A1"></v-divider>
                        </td></tr>

                      </template>
                    </tbody>
                  </table>
                </div>
              </div>
            </template>

            <!-- <br />
            <v-divider></v-divider> -->
            <br />
            <v-row>
              <v-spacer></v-spacer>
              <v-btn class="mr-4" @click="cancel">
                Cancel
              </v-btn>

              <v-btn color="primary" type="submit" v-if="userCode != '9a0064aa-8c44-4597-b60f-7dc8dcb8c532'">
                Confirm
              </v-btn>
            </v-row>

          </v-form>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import api from "@/services/lambdaApi";
import XLSX from 'xlsx';

export default {
  name: "import-product-info",
  // components:{
  // },
  data: () => ({
    loading: false,
    mDataArray: []
  }),
  async mounted() {
  },
  methods: {
    removeItem: function (item) {
      let mProduct = this.mDataArray.find(d => d.cprcode === item.cprcode);
      if (mProduct) {
        let index = this.mDataArray.indexOf(mProduct);
        if (index >= 0) {
          this.mDataArray.splice(index, 1)
        }
      }
    },
    removeItemStore: function (item, data) {
      let mItem = item.avail_store.find(d => d.store === data.store);
      if (mItem) {
        let index = item.avail_store.indexOf(mItem);
        if (index >= 0) {
          item.avail_store.splice(index, 1)
        }
      }
    },
    getValue(mProduct, fieldName) {
      return (mProduct[fieldName] ?? "").toUpperCase() == "Y";
    },
    addStoreItem(avail_store, mProduct, brcode) {
      let store = `store_${brcode}`;
      if (this.getValue(mProduct, store)) {
        let store_visibility = `store_${brcode}_visibility`;
        avail_store.push({
          store: brcode,
          visibility: this.getValue(mProduct, store_visibility)
        });
      }
    },
    getAvailStore(mProduct) {
      let avail_store = [];
      this.addStoreItem(avail_store, mProduct, "1000");
      this.addStoreItem(avail_store, mProduct, "1001");
      this.addStoreItem(avail_store, mProduct, "1003");
      this.addStoreItem(avail_store, mProduct, "1005");
      this.addStoreItem(avail_store, mProduct, "1006");
      this.addStoreItem(avail_store, mProduct, "1007");
      this.addStoreItem(avail_store, mProduct, "1011");
      this.addStoreItem(avail_store, mProduct, "1012");
      this.addStoreItem(avail_store, mProduct, "1014");
      this.addStoreItem(avail_store, mProduct, "1015");
      this.addStoreItem(avail_store, mProduct, "1016");
      this.addStoreItem(avail_store, mProduct, "1017");
      this.addStoreItem(avail_store, mProduct, "1019");
      this.addStoreItem(avail_store, mProduct, "1020");
      this.addStoreItem(avail_store, mProduct, "1021");
      this.addStoreItem(avail_store, mProduct, "1022");
      this.addStoreItem(avail_store, mProduct, "1023");
      this.addStoreItem(avail_store, mProduct, "1024");
      this.addStoreItem(avail_store, mProduct, "1026");
      this.addStoreItem(avail_store, mProduct, "1030");
      this.addStoreItem(avail_store, mProduct, "1032");
      this.addStoreItem(avail_store, mProduct, "1033");
      this.addStoreItem(avail_store, mProduct, "1034");
      this.addStoreItem(avail_store, mProduct, "1036");
      this.addStoreItem(avail_store, mProduct, "1038");
      this.addStoreItem(avail_store, mProduct, "1039");
      this.addStoreItem(avail_store, mProduct, "1040");
      this.addStoreItem(avail_store, mProduct, "1041");
      this.addStoreItem(avail_store, mProduct, "1042");
      this.addStoreItem(avail_store, mProduct, "1044");
      this.addStoreItem(avail_store, mProduct, "1046");
      this.addStoreItem(avail_store, mProduct, "1047");
      this.addStoreItem(avail_store, mProduct, "1048");
      this.addStoreItem(avail_store, mProduct, "1049");
      this.addStoreItem(avail_store, mProduct, "1050");
      this.addStoreItem(avail_store, mProduct, "1051");
      this.addStoreItem(avail_store, mProduct, "1052");
      this.addStoreItem(avail_store, mProduct, "1053");
      this.addStoreItem(avail_store, mProduct, "1054");
      this.addStoreItem(avail_store, mProduct, "1055");
      this.addStoreItem(avail_store, mProduct, "1056");
      this.addStoreItem(avail_store, mProduct, "1057");
      this.addStoreItem(avail_store, mProduct, "3000");
      return avail_store;
    },
    addProductInfo: function (prcodeList, item) {
      let mProduct = prcodeList.find(d => parseInt(d.prcode) === parseInt(item.cprcode));
      if (mProduct) {
        item["hema_name_en"] = (mProduct["hema_name_en"] ?? "").toString();
        item["hema_name_th"] = (mProduct["hema_name_th"] ?? "").toString();
        item["online_category_l1_en"] = (mProduct["online_category_l1_en"] ?? "").toString();
        item["online_category_l2_en"] = (mProduct["online_category_l2_en"] ?? "").toString();
        item["online_category_l3_en"] = (mProduct["online_category_l3_en"] ?? "").toString();
        item["pr_country_en"] = (mProduct["pr_country_en"] ?? "").toString();
        item["hema_brand_en"] = (mProduct["hema_brand_en"] ?? "").toString();
        item["hema_sizedesc"] = (mProduct["hema_sizedesc"] ?? "").toString();
        item["avail_store"] = this.getAvailStore(mProduct);
        item["portion_size"] = parseInt(mProduct["portion_size"] ?? 0);
        item["psqty"] = parseInt(mProduct["psqty"] ?? 0);
        item["warehouse"] = parseInt(mProduct["warehouse"] ?? 0);
        item["consign_inv"] = parseInt(mProduct["consign_inv"] ?? 0);
        // item["preorder_delivery_type"] = mProduct["preorder_delivery_type"];
        // item["preorder_fix_date"] = mProduct["preorder_fix_date"];
        // item["preorder_relative_day"] = parseInt(mProduct["preorder_relative_day"] ?? 0);
        item["meta_title"] = (mProduct["meta_title"] ?? "").toString();
        item["meta_keywords"] = (mProduct["meta_keywords"] ?? "").toString();
        item["meta_description"] = (mProduct["meta_description"] ?? "").toString();
        item["height"] = parseFloat(mProduct["height"] ?? 0);
        item["width"] = parseFloat(mProduct["width"] ?? 0);
        item["depth"] = parseFloat(mProduct["depth"] ?? 0);
        item["weight"] = parseFloat(mProduct["weight"] ?? 0);
        // item["priority_score"] = parseFloat(mProduct["priority_score"] ?? 0);
        item["master_online"] = (mProduct["online"] ?? "").toUpperCase() == "Y" ? true : false;
        // console.log('-- item --', item);
      }
    },
    cancel() {
      this.$router.back();
    },
    clearData() {
      this.mDataArray = []
    },
    onFileImportSelected(e) {
      try {
        this.loading = true;
        var files = e.target.files || e.dataTransfer.files;
        if (files.length == 0)
          return;
        this.previewFiles(files[0]);
      // } catch (error) {
      }
      finally {
        this.$refs.importFile.value = "";
        setTimeout(() => {
          this.loading = false;
        }, 2000);
      }
    },
    previewFiles(file) { // detect file event
        var reader = new FileReader();
        var vm = this;
        reader.onload = async function(e) {
            var data = new Uint8Array(e.target.result);
            var workbook = XLSX.read(data, { type: "array" });
            let sheetName = workbook.SheetNames[0];
            /* DO SOMETHING WITH workbook HERE */
            // console.log(workbook);
            let worksheet = workbook.Sheets[sheetName];
            // console.log(XLSX.utils.sheet_to_json(worksheet));

            let prcodes = XLSX.utils.sheet_to_json(worksheet);
            // console.log('-- prcodes --', prcodes);
            let prcodeList = [];
            prcodes.forEach(item => {
              prcodeList.push(parseInt(item.prcode ?? 0));
            });
            if (prcodeList && prcodeList.length>0) {
              // console.log('-- prcodeList --', prcodeList);
              let result = await api.getProductByPrcodeList(prcodeList);
              let dataArray  = result.data;
              dataArray.forEach(item => {
                vm.addProductInfo(prcodes, item);
              });
              vm.mDataArray = dataArray;
              // console.log('-- vm.mDataArray --', vm.mDataArray);
            }
        };
        reader.readAsArrayBuffer(file);
    },
    async submit() {
      try {
        this.loading = true;
        // console.log('-- vm.mDataArray --', this.mDataArray);
        let result = await api.setProductImport(JSON.stringify(this.mDataArray));
        console.log('result',result);
        this.$router.back();
      // } catch (error) {
      }
      finally {
        this.loading = false;
      }
    },
  },
  computed: {
    userCode() {
      return this.$store.getters["userInfo"]["userCode"]?.toString() ?? "";
    },
  }
};
</script>

<style scoped>
table {
  border-spacing: 0 0.1rem;
}
tbody tr:nth-of-type(odd) {
  background-color: rgba(0, 0, 0, .05);
}
.table_store {
   height: 270px;
   overflow: auto;
}
.subject {
  color: #0D47A1;
}
</style>