<template>
  <div>
    <v-container v-if="loading">
    <div class="text-center">
      <v-progress-circular indeterminate :size="80" :width="8" color="primary">
      </v-progress-circular>
    </div>
  </v-container>
  <v-container fluid v-else>
    <v-alert
      v-model="show_success"
      dismissible
      outlined
      text
      type="success"
    >Update ข้อมูลเว็บ เรียบร้อยแล้ว</v-alert>
    <v-alert
      v-model="show_error"
      dismissible
      outlined
      text
      type="error"
    >Update ข้อมูลเว็บ ไม่สำเร็จ!!</v-alert>
    <!-- Table section -->
    <v-card>
      <v-data-table
        :headers="headers"
        :items="mDataArray"
        sort-by="groupId"
        :sort-desc="true"
        :items-per-page="5"
      >
        <!-- table top section -->
        <template v-slot:top>
          <v-toolbar flat color="white">
            <v-toolbar-title>Banner</v-toolbar-title>
            <v-divider class="mx-4" inset vertical></v-divider>
            <v-select
              v-model="bannerType"
              :items="['all', 'home', 'category', 'promotion', 'sticky']"
              label="Banner Type"
              hide-details
            ></v-select>
            <!-- <v-divider class="mx-4" inset vertical></v-divider>
            <v-text-field
              v-model="search"
              label="Search"
              single-line
              hide-details
            ></v-text-field>
            <v-icon class="mr-2" @click="searchClick">
              search
            </v-icon> -->
            <!-- <v-divider class="mx-4" inset vertical></v-divider>
            <v-checkbox
              v-model="enabled"
              label="Enabled"
              hide-details
            ></v-checkbox> -->
            <v-divider class="mx-4" inset vertical></v-divider>
            <v-icon class="mr-2" @click="searchClick">
              search
            </v-icon>
            <v-spacer></v-spacer>
            <v-btn @click="homeSort" color="blue darken-2" dark class="mb-2">
              <v-icon left>sort</v-icon>
              <span>Home Sort</span>
            </v-btn>
            <v-divider class="mx-4" inset vertical></v-divider>
            <v-btn @click="catSort" color="indigo darken-2" dark class="mb-2">
              <v-icon left>sort</v-icon>
              <span>Cat Sort</span>
            </v-btn>
            <v-divider class="mx-4" inset vertical></v-divider>
            <v-btn @click="refreshGroup" color="green darken-2" dark class="mb-2" v-if="userCode != '9a0064aa-8c44-4597-b60f-7dc8dcb8c532'">
              <v-icon left>refresh</v-icon>
              <span>Update Web</span>
            </v-btn>
            <v-divider class="mx-4" inset vertical></v-divider>
            <v-btn @click="newBanner" color="primary" dark class="mb-2">
              <v-icon left>add</v-icon>
              <span>New Banner</span>
            </v-btn>
          </v-toolbar>
        </template>

        <!-- table tr section -->
        <template v-slot:item="{ item }">
          <tr>
            <td>
              <v-img
                contain
                class="ma-2"
                :eager="true"
                :src="item.metadata.imageUrl[0]"
                lazy-src="@/assets/noimage.png"
                aspect-ratio="1"
                min-width="80"
                max-width="80"
                max-height="80"
              >
                <template v-slot:placeholder>
                  <v-row
                    class="fill-height ma-0"
                    align="center"
                    justify="center"
                  >
                    <v-progress-circular
                      v-show="loading"
                      indeterminate
                      color="grey lighten-5"
                    ></v-progress-circular>
                  </v-row>
                </template>
              </v-img>
            </td>
            <td>{{ item.groupId }}</td>
            <td>{{ item.groupName }}</td>
            <td>{{ item.metadata.bannerType }}</td>
            <td>{{ item.metadata.categorylv1Name }}</td>
            <td>{{ item.metadata.slotIndex }}</td>
            <td>{{ item.metadata.startDate }}</td>
            <td>{{ item.metadata.endDate }}</td>
            <td>
              <v-checkbox readonly v-model="item.enabled"></v-checkbox>
            </td>
            <td>
              <v-icon class="mr-2" @click="editItem(item)">
                edit
              </v-icon>
              <v-icon class="mr-2" @click="refreshPuppeteerRender(item)">
                refresh
              </v-icon>
            </td>
          </tr>
        </template>
      </v-data-table>

      <!-- <v-dialog v-model="confirmNewBannerDlg" max-width="290">
        <v-card>
          <v-card-title primary-title>
            Confirm New Banner
          </v-card-title>

          <v-card-text class="body">
            Are you sure to new banner.
          </v-card-text>

          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn text @click="confirmNewBannerDlg = false">
              Cancel
            </v-btn>

            <v-btn color="error" text @click="confirmNewBanner">
              Confirm
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog> -->
    </v-card>
  </v-container>
  </div>
</template>

<script>
import api from "@/services/lambdaApi";

export default {
  name: "banner",
  data() {
    return {
      loading: false,
      //search: "",
      show_success: false,
      show_error: false,
      confirmNewBannerDlg: false,
      bannerType: "all",
      // enabled: true,
      mDataArray: [],
      mHomeSortArray: [],
      mCatSortArray: [],
      headers: [
        {
          text: "Image",
          align: "left",
          sortable: false,
          value: "objectUrl",
        },
        { text: "Banner Id", value: "groupId" },
        { text: "Name", value: "groupName" },
        { text: "Type", value: "metadata.bannerType" },
        { text: "Category Lv1", value: "metadata.categorylv1Name" },
        { text: "Slot Index", value: "metadata.slotIndex" },
        { text: "Start Datetime", value: "metadata.startDate" },
        { text: "End Datetime", value: "metadata.endDate" },
        {
          text: "Enabled",
          align: "left",
          // sortable: false,
          value: "enabled",
        },
        {
          text: "Action",
          align: "left",
          sortable: false,
          value: "action",
        },
      ],
    };
  },
  // watch: {
  //   mDataArray: {
  //     immediate: true,
  //     handler: function(newValue, oldValue) {
  //       console.log("watch: mDataArray:", newValue, oldValue);
  //     },
  //   },
  // },
  // components: {
  // },
  async mounted() {
    // console.log("mount bander index");
    this.loadBanner();
  },
  methods: {
    homeSort() {
      this.$router.push("/banner/homesort");
    },
    catSort() {
      this.$router.push("/banner/categorysort");
    },
    searchClick() {
      this.loadBanner();
    },
    editItem(item) {
      this.$router.push(`/banner/edit/${item.groupId}`);
    },
    async refreshPuppeteerRender(item) {
      try {
        this.loading = true;
        let data = { groupId: item.groupId };
        let response = await api.refreshPuppeteerRender(data);
        // console.log("--- refreshPuppeteerRender ---", response.data);
        if (response.data.statusCode == 200) {
          this.show_error = false;
          this.show_success = true;
        } else {
          this.show_error = true;
          this.show_success = false;
        }
        // } catch (error) {
      } finally {
        this.loading = false;
      }
    },
    async refreshGroup() {
      try {
        this.loading = true;
        let response = await api.refreshGroup({});
        // console.log("--- refreshGroup ---", response.data);
        if (response.data.statusCode == 200) {
          this.show_error = false;
          this.show_success = true;
        } else {
          this.show_error = true;
          this.show_success = false;
        }
        // } catch (error) {
      } finally {
        this.loading = false;
      }
    },
    newBanner() {
      this.$router.push("/banner/edit/0");
      //this.confirmNewBannerDlg = true;
    },
    async confirmNewBanner() {
      await api.newBanner({});
      this.confirmNewBannerDlg = false;
      this.loadBanner();
    },
    async loadBanner() {
      try {
        this.loading = true;
        // let enabled_value = this.enabled ? 1 : 0;
        // let data = { bannerType: this.bannerType, enabled: enabled_value };
        let data = { groupId: 8000000000, bannerType: (this.bannerType=="all" ? "" : this.bannerType) };
        let result = await api.getBanner(data);
        // console.log("--- banner list ---", result.data);

        this.mDataArray = result.data;
        // setTimeout(() => {
        //   this.mDataArray = result.data;
        // }, 1000);
        // } catch (error) {
      } finally {
        this.loading = false;
      }
    },
  },
  computed: {
      userCode() {
        return this.$store.getters["userInfo"]["userCode"]?.toString() ?? "";
      }
  }
};
</script>

<style scoped>
.v-progress-circular {
  margin: 1rem;
}
v-data-table table {
  border-spacing: 0 0.5rem;
}
tbody tr:nth-of-type(odd) {
  background-color: rgba(0, 0, 0, 0.05);
}
</style>
