<template>
  <v-container v-if="loading">
    <div class="text-center">
      <v-progress-circular indeterminate :size="80" :width="8" color="primary">
      </v-progress-circular>
    </div>
  </v-container>
  <v-container fluid v-else>
    <v-row>
      <v-col lg="3" md="3" sm="12" cols="12">
        <v-menu
          hide-details
          ref="menu"
          v-model="menu"
          :close-on-content-click="false"
          :return-value.sync="date"
          transition="scale-transition"
          offset-y
          min-width="auto">
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
              v-model="date"
              label="StoreDate"
              prepend-icon="mdi-calendar"
              :disabled="userBrcode != ''"
              readonly
              v-bind="attrs"
              v-on="on"
            ></v-text-field>
          </template>
          <v-date-picker
            @input="$refs.menu.save(date)"
            v-model="date"
            no-title
            scrollable>
          </v-date-picker>
        </v-menu>
      </v-col>
      <v-col lg="3" md="3" sm="12" cols="12">
        <v-text-field
            clearable
            hide-details
            v-model="orderId"
            label="OrderId"
            @keyup.enter="searchClick"
        ></v-text-field>
      </v-col>
      <v-col lg="6" md="6" sm="12" cols="12">
        <v-btn class="mt-3" @click="loadData" color="primary">
        <v-icon left>search</v-icon>
        <span>Query</span>
        </v-btn>
        <v-btn class="ml-3 mt-3" @click="exportClick" color="primary">
          <v-icon left>save</v-icon>
          <span>Export</span>
        </v-btn>
        <v-btn class="ml-3 mt-3" @click="confirmClick" color="green darken-2" dark v-if="userCode != '9a0064aa-8c44-4597-b60f-7dc8dcb8c532'">
        <v-icon left>done</v-icon>
        <span>Confirm</span>
        </v-btn>
        <v-btn class="ml-3 mt-3" @click="saveClick" color="red darken-2" dark v-if="userGroup == 'it' && (userCode != '9a0064aa-8c44-4597-b60f-7dc8dcb8c532')">
        <v-icon left>save</v-icon>
        <span>Save To POS</span>
        </v-btn>
      </v-col>
    </v-row>

    <v-card>
        <v-data-table
            height="500"
            fixed-header
            :headers="headers"
            :items="mDataArray"
            sort-by="storeDate"
            :sort-desc="true"
            :items-per-page="-1"
        >
        <template v-slot:item="{ item }">
            <tr>
                <td>
                  <v-checkbox v-model="item.confirm"></v-checkbox>
                </td>
                <td>{{ item.storeDate }}</td>
                <td>{{ item.orderDate }}</td>
                <td>{{ item.orderId }}</td>
                <td>{{ item.isExistsOrder == 1 ? 'Y' : 'N' }}</td>
                <td>{{ item.voucherCode }}</td>
                <td style="text-align: right">{{ numberFormat(item.amount, 2) }}</td>
                <td>{{ item.custResponse }}</td>
                <td>{{ item.paymentMethod }}</td>
                <td>{{ item.requestCode }}</td>
                <td>{{ item.requestDesc }}</td>
                <td>
                  <v-checkbox readonly v-model="item.voucherFlag"></v-checkbox>
                </td>
                <td>{{ item.voucherFlagTimeStamp }}</td>
            </tr>
        </template>
        </v-data-table>
    </v-card>
  </v-container>
</template>

<script>
  import api from "@/services/lambdaApi";
  import moment from 'moment'
  import XLSX from 'xlsx';

  export default {
    name: "autorefund",
    // components: {
    // },
    data () {
      return {
        loading: false,
        date: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
        menu: false,
        orderId: "",
        mDataArray: [],
        headers: [
            { text: "Select", value: "confirm" },
            { text: "StoreDate", value: "storeDate" },
            { text: "OrderDate", value: "orderDate" },
            { text: "OrderId", value: "orderId" },
            { text: "@", value: "isExistsOrder" },
            { text: "VoucherCode", value: "voucherCode" },
            { text: "Amount", value: "amount", align: "right" },
            { text: "CustResponse", value: "custResponse" },
            { text: "PaymentMethod", value: "paymentMethod" },
            { text: "ReqBy", value: "requestCode" },
            { text: "ReqDesc", value: "requestDesc" },
            { text: "Confirm", value: "voucherFlag" },
            { text: "ConfirmDate", value: "voucherFlagTimeStamp" },
        ],
      }
    },
    async mounted () {
        let storeDate = (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10);
        this.date = moment(this.date).subtract(2, 'days').format('YYYY-MM-DD');
        await this.loadData();
    },
    methods: {
        async saveClick() {
          try {
            this.loading = true;
            let cdate = (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10);
            const dataWS = XLSX.utils.json_to_sheet(this.dataExportPos)
            const wb = XLSX.utils.book_new()
            XLSX.utils.book_append_sheet(wb, dataWS)
            XLSX.writeFile(wb,`autoRefundPos_${moment(this.cdate).format('YYYYMMDD')}.xlsx`)
          } catch (error) {
            alert(error)
          } finally {
            this.loading = false;
          }
        },
        async exportClick() {
          try {
            this.loading = true;
            let cdate = (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10);
            const dataWS = XLSX.utils.json_to_sheet(this.dataExport)
            const wb = XLSX.utils.book_new()
            XLSX.utils.book_append_sheet(wb, dataWS)
            XLSX.writeFile(wb,`autoRefund_${moment(this.cdate).format('YYYYMMDD')}.xlsx`)
          } catch (error) {
            alert(error)
          } finally {
            this.loading = false;
          }
        },
        async confirmClick() {
          if (confirm('Confirm Refund?')) {
            try {
                this.loading = true;
                let data = {
                  userCode: this.userCode,
                  items: []
                };
                let items = [];
                if (this.mDataArray != null && this.mDataArray.length > 0) {
                  this.mDataArray.forEach(item => {
                    if ((item["voucherFlage"] ?? 0) == 0 && (item["confirm"] ?? 0 == 1)) {
                      //เปลี่ยนเป็น voucherCode เพราะ orderId ซ้ำได้
                      // items.push(item["orderId"])
                      items.push(item["voucherCode"])
                    };
                  });
                };
                data["items"] = items;
                // console.log("--- request ---", data);
                let result = await api.confirmAutoRefund(data);
                // console.log("--- response ---", result.data);
                await this.loadData();
            } catch (error) {
                alert(error)
            } finally {
                this.loading = false;
            }
          }
        },
        numberFormat(value, decimal) {
          return parseFloat(value?? 0).toLocaleString("th-TH", {"minimumFractionDigits":decimal,"maximumFractionDigits":decimal});
          // return parseFloat(this.mData?.amounts2 ?? 0).toLocaleString("th-TH", {"minimumFractionDigits":2,"maximumFractionDigits":2});
        },
        async loadData () {
            try {
                let storeDate = this.date == null || this.date == "" ? "" : moment(this.date).format('YYYY-MM-DD');
                if (storeDate == "") {
                  alert('Please enter StoreDate');
                  return;
                }
                this.loading = true;
                let filter = { action: "voucher", voucherCode: "", orderId: this.orderId, storeDate: storeDate }
                // console.log("--- filter ---", filter);
                let result = await api.getVoucherList(filter);
                // console.log("--- data list ---", result.data);
                this.mDataArray = result.data;
                if (this.mDataArray != null && this.mDataArray.length > 0) {
                  this.mDataArray.forEach(item => {
                    item["confirm"] = item["voucherFlage"] ?? 0 == 1;
                  });
                }
            } catch (error) {
                alert(error)
            } finally {
                this.loading = false;
            }
        },
    },

    computed: {
      userCode() {
        return this.$store.getters["userInfo"]["userCode"]?.toString() ?? "";
      },
      userGroup() {
        return this.$store.getters["userInfo"]["userGroup"]?.toString().toLowerCase().trim() ?? "";
      },
      userType() {
        return this.$store.getters["userInfo"]["userType"]?.toString() ?? "";
      },
      userBrcode() {
        return this.$store.getters["userInfo"]["brcode"]?.toString() ?? "";
      },
    dataExport() {
      if (this.mDataArray == null)
        return []
      else {
        let dataResult = [];
        this.mDataArray.forEach(item => {
          var data = {
            "storeDate": item['storeDate'] ?? "", 
            "orderDate": item['orderDate'] ?? "", 
            "orderId": item['orderId'] ?? "",
            "voucherCode": item['voucherCode'] ?? "",
            "amount": item['amount'] ?? "",
            "custResponse": item['custResponse'] ?? "",
            "paymentMethod": item['paymentMethod'] ?? 0,
            "confirm": (item['voucherFlag'] ?? 0 == 1) ? "Y" : "N",
            "confirmDate": item['voucherFlagTimeStamp'] ?? "",
          };
          dataResult.push(data);
        });
        return dataResult;
      };
    },
    dataExportPos() {
      if (this.mDataArray == null)
        return []
      else {
        let dataResult = [];
        this.mDataArray.forEach(item => {
          if (item["voucherFlag"] ?? 0 == 1) {
            var data = {
              "orderId": item['orderId'] ?? "",
              "voucherCode": item['voucherCode'] ?? "",
              "amount": item['amount'] ?? "",
              "remark": item['remark'] ?? "",
            };
            dataResult.push(data);
          };
        });
        return dataResult;
      };
    },
    }
  }
</script>

<style scoped>
.v-progress-circular {
  margin: 1rem;
}
v-data-table table {
  border-spacing: 0 0.5rem;
}
tbody tr:nth-of-type(odd) {
  background-color: rgba(0, 0, 0, 0.05);
}
</style>