<template>
    <v-container v-if="loading">
      <div class="text-center">
        <v-progress-circular indeterminate :size="80" :width="8" color="primary">
        </v-progress-circular>
      </div>
    </v-container>
    <v-container fluid v-else>
      <v-row>
        <v-col lg="2" md="2" sm="12" cols="12">
          <v-menu
            hide-details
            ref="menu1"
            v-model="menu1"
            :close-on-content-click="false"
            :return-value.sync="date1"
            transition="scale-transition"
            offset-y
            min-width="auto">
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                v-model="date1"
                label="OrderDate (Start)"
                prepend-icon="mdi-calendar"
                readonly
                v-bind="attrs"
                v-on="on"
              ></v-text-field>
            </template>
            <v-date-picker
              @input="$refs.menu1.save(date1)"
              v-model="date1"
              no-title
              scrollable>
            </v-date-picker>
          </v-menu>
        </v-col>
        <v-col lg="2" md="2" sm="12" cols="12">
          <v-menu
            hide-details
            ref="menu2"
            v-model="menu2"
            :close-on-content-click="false"
            :return-value.sync="date2"
            transition="scale-transition"
            offset-y
            min-width="auto">
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                v-model="date2"
                label="OrderDate (End)"
                prepend-icon="mdi-calendar"
                readonly
                v-bind="attrs"
                v-on="on"
              ></v-text-field>
            </template>
            <v-date-picker
              @input="$refs.menu2.save(date2)"
              v-model="date2"
              no-title
              scrollable>
            </v-date-picker>
          </v-menu>
        </v-col>
        <v-col lg="2" md="2" sm="12" cols="12">
          <v-select
            v-model="reportType"
            :items="['All', 'TeleSale', 'Online']"
            label="Select"
            required
          ></v-select>
        </v-col>
        <v-col lg="2" md="2" sm="12" cols="12">
          <v-checkbox
            v-model="isNationwide"
            label="NATIONWIDE"
            hide-details
          ></v-checkbox>
        </v-col>
        <v-col lg="4" md="4" sm="12" cols="12">
          <v-btn class="mt-3" @click="loadData" color="primary">
          <v-icon left>search</v-icon>
          <span>Query</span>
          </v-btn>
          <v-btn class="ml-3 mt-3" @click="exportClick" color="primary">
            <v-icon left>save</v-icon>
            <span>Export</span>
          </v-btn>
          <v-btn class="ml-3 mt-3" @click="updateSaleReport" color="red darken-2" dark v-if="userCode != '9a0064aa-8c44-4597-b60f-7dc8dcb8c532'">
          <v-icon left>save</v-icon>
          <span>Update Sale</span>
          </v-btn>
        </v-col>
      </v-row>
  
      <v-card class="mt-1">
          <v-data-table
              height="500"
              fixed-header
              :headers="headers"
              :items="mDataArray"
              :sort-desc="false"
              :items-per-page="15"
          >
          <template v-slot:item="{ item }">
              <tr>
                  <td>
                    <v-icon class="mr-2" @click="editItem(item)">
                      edit
                    </v-icon>
                  </td>
                  <td>{{ item.brcode }}</td>
                  <td>{{ item.brname }}</td>
                  <td>{{ item.orderId }}</td>
                  <td>{{ item.timestamp }}</td>
                  <!-- <td>{{ item.timeAt }}</td> -->
                  <td style="text-align: right">{{ numberFormat(item.amount, 2) }}</td>
                  <td style="text-align: right">{{ numberFormat(item.shippingAmount, 2) }}</td>
                  <td style="text-align: right">{{ numberFormat(item.tele_amount, 2) }}</td>
                  <td style="text-align: right">{{ numberFormat(item.tele_shipping, 0) }}</td>
              </tr>
          </template>
          </v-data-table>
      </v-card>
    </v-container>
  </template>
  
  <script>
    import api from "@/services/lambdaApi";
    import moment from 'moment'
    import XLSX from 'xlsx';
  
    export default {
      name: "saleorderreport",
      // components: {
      // },
      data () {
        return {
          loading: false,
          isNationwide: false,
          reportType: "TeleSale",
          date1: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
          menu1: false,
          date2: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
          menu2: false,
          mDataArray: [],
          headers: [
              {
                text: "Action",
                align: "left",
                sortable: false,
                value: "action",
                width: 60
              },
              { text: "Brcode", value: "brcode" },
              { text: "Brname", value: "brname" },
              { text: "OrderId", value: "orderId" },
              { text: "OrderDate", value: "timestamp" },
              // { text: "TimeAt", value: "timeAt" },
              { text: "Revenue", value: "amount", align: "right" },
              { text: "Shipping Amount", value: "shippingAmount", align: "right" },
              { text: "TeleSale Revenue", value: "tele_amount", align: "right" },
              { text: "TeleSale Shipping", value: "tele_shipping", align: "right" },
          ],
        }
      },
      // async mounted () {
      //     await this.loadData();
      // },
      methods: {
          editItem(item) {
            this.$router.push(`/order/edit/${item.orderId}`);
          },
          async exportClick() {
            try {
              this.loading = true;
              let cdate = (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10);
              const dataWS = XLSX.utils.json_to_sheet(this.mDataArray)
              const wb = XLSX.utils.book_new()
              XLSX.utils.book_append_sheet(wb, dataWS)
              XLSX.writeFile(wb,`saleOrderReport_${moment(cdate).format('YYYYMMDD')}.xlsx`)
            } catch (error) {
              alert(error)
            } finally {
              this.loading = false;
            }
          },
          numberFormat(value, decimal) {
            return parseFloat(value?? 0).toLocaleString("th-TH", {"minimumFractionDigits":decimal,"maximumFractionDigits":decimal});
          },
          async updateSaleReport()
          {
            try {
                  this.loading = true;
                  let startDate = this.date1 == null || this.date1 == "" ? "" : moment(this.date1).format('YYYY-MM-DD');
                  let data = { datestr:startDate };
                  // console.log("--- filter ---", filter);
                  let result = await api.updateDailyReport(data);
                  alert(result.data);
                  // console.log("--- data list ---", result.data);
                  // this.mDataArray = result.data;
              } catch (error) {
                  alert(error)
              } finally {
                  this.loading = false;
              }
          },
          async loadData () {
              try {
                  this.loading = true;
                  let startDate = this.date1 == null || this.date1 == "" ? "" : moment(this.date1).format('YYYY-MM-DD');
                  let endDate = this.date2 == null || this.date2 == "" ? "" : moment(this.date2).format('YYYY-MM-DD');
                  let isTeleSale = 1;
                  switch (this.reportType) {
                    case "TeleSale":
                      isTeleSale = 1;
                      break;
                    case "Online":
                      isTeleSale = 2;
                      break;
                    default:
                      isTeleSale = 0;
                      break;
                  }
                  let nationWide = this.isNationwide == true ? 1 : 0;
                  let filter = { action: "saleorderreport", brcode: "", startDate: startDate, endDate: endDate, isTelesale: isTeleSale, isNationwide: nationWide };
                  // console.log("--- filter ---", filter);
                  let result = await api.getReports(filter);
                  // console.log("--- data list ---", result.data);
                  this.mDataArray = result.data;
              } catch (error) {
                  alert(error)
              } finally {
                  this.loading = false;
              }
          },
      },
  
      computed: {
        userCode() {
          return this.$store.getters["userInfo"]["userCode"]?.toString() ?? "";
        },
        userGroup() {
          return this.$store.getters["userInfo"]["userGroup"]?.toString().toLowerCase().trim() ?? "";
        },
        userType() {
          return this.$store.getters["userInfo"]["userType"]?.toString() ?? "";
        },
        userBrcode() {
          return this.$store.getters["userInfo"]["brcode"]?.toString() ?? "";
        },
      }
    }
  </script>
  
  <style scoped>
  .v-progress-circular {
    margin: 1rem;
  }
  v-data-table table {
    border-spacing: 0 0.5rem;
  }
  tbody tr:nth-of-type(odd) {
    background-color: rgba(0, 0, 0, 0.05);
  }
  </style>