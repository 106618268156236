<template>
  <v-container v-if="loading">
    <div class="text-center">
      <v-progress-circular indeterminate :size="80" :width="8" color="primary">
      </v-progress-circular>
    </div>
  </v-container>
  <v-container fluid v-else>
    <v-row>
      <v-col lg="3" md="3" sm="12" cols="12">
        <v-text-field
            v-model="search"
            label="Search"
            single-line
            @keyup.enter="searchClick"
        ></v-text-field>
      </v-col>
      <v-col lg="9" md="9" sm="12" cols="12">
        <v-btn class="mt-3" @click="searchClick" color="primary">
        <v-icon left>search</v-icon>
        <span>Query</span>
        </v-btn>
        <v-btn class="ml-3 mt-3" @click="exportClick" color="primary">
          <v-icon left>save</v-icon>
          <span>Export</span>
        </v-btn>
        <v-btn class="ml-3 mt-3" @click="confirmClick" color="green darken-2" dark v-if="userCode != '9a0064aa-8c44-4597-b60f-7dc8dcb8c532'">
          <v-icon left>done</v-icon>
          Confirm
        </v-btn>
      </v-col>
    </v-row>

    <template>
      <v-row class="ma-0">
        <h3 class="mr-3">Import Excel (XLSX)</h3>
        <input type="file" accept=".xlsx" @change="onFileImportSelected" ref="importFile">
      </v-row>
    </template>

    <template>
      <div class="row">
        <div class="col-12">
          <table class="table table-striped" width="100%">
            <thead class="thead-dark" align-center>
              <tr>
                <th scope="col" style="text-align: left">Image</th>
                <th scope="col" style="text-align: left">(C) PrCode)</th>
                <th scope="col" style="text-align: left">PrName</th>
                <th scope="col" style="text-align: left">CategoryLv1</th>
                <th scope="col" style="text-align: left">CategoryLv2</th>
                <th scope="col" style="text-align: left">CategoryLv3</th>
                <th scope="col" style="text-align: left">Brand</th>
                <th scope="col" style="text-align: left">SizeDesc</th>
                <!-- <th scope="col" style="text-align: left">Seq</th> -->
                <th scope="col" style="text-align: left">Online</th>
              </tr>
            </thead>
            <draggable v-model="mDataArray" tag="tbody">
              <tr v-for="item in mDataArray" :key="item.cprcode">
                <td>
                    <v-img
                      contain
                      class="ma-2"
                      :eager="true"
                      :src="getImageUrl(item)"
                      lazy-src="@/assets/noimage.png"
                      aspect-ratio="1"
                      min-width="80"
                      max-width="80"
                      max-height="80"
                    >
                      <template v-slot:placeholder>
                        <v-row
                          class="fill-height ma-0"
                          align="center"
                          justify="center"
                        >
                          <v-progress-circular v-show="loading"
                            indeterminate
                            color="grey lighten-5"
                          ></v-progress-circular>
                        </v-row>
                      </template>
                    </v-img>
                </td>
                <td>{{ item.cprcode }}</td>
                <td>{{ item.hema_name_en }}</td>
                <td>{{ item.online_category_l1_en }}</td>
                <td>{{ item.online_category_l2_en }}</td>
                <td>{{ item.online_category_l3_en }}</td>
                <td>{{ item.hema_brand_en }}</td>
                <td>{{ item.hema_sizedesc }}</td>
                <!-- <td>{{ item.sort_villa_sku }}</td> -->
                <td>
                    <v-icon class="mr-2" color="green darken-2" v-if="item.master_online == true">
                        done
                    </v-icon>
                    <v-icon class="mr-2" color="red darken-2" v-else>
                        close
                    </v-icon>
                </td>
              </tr>
            </draggable>
          </table>
        </div>
      </div>
    </template>
  </v-container>
</template>

<script>
  import { imageUrl } from "@/services/constants";
  import api from "@/services/lambdaApi";
  import moment from 'moment'
  import draggable from 'vuedraggable'
  import XLSX from 'xlsx';
  
  export default {
    name: "product-search-seq",
    name: "",
    components: {
      draggable
    },
    data () {
      return {
        loading: false,
        search: "",
        mDataArray: [],
      }
    },
    // async mounted () {
    //   try {
    //     this.loading = true;
    //     //****** */
    //   } catch (error) {
    //     alert(error)
    //   } finally {
    //     this.loading = false;
    //   }
    // },
    methods: {
        async confirmClick() {
          if (confirm('Confirm Save?')) {
              try {
                this.loading = true;
                let seq = this.mDataArray.length;
                let dataArray = [];
                this.mDataArray.forEach(item => {
                  dataArray.push(
                    {
                      cprcode: item.cprcode ?? 0,
                      sort_villa_sku: seq--
                    }
                  )
                });
                // console.log('--- dataArray ---', JSON.stringify(dataArray));
                if (dataArray && dataArray.length>0) {
                    var start = 0;
                    var stop = 0;
                    while (stop <= dataArray.length) {
                        stop = start + 1000;
                        var dataUpdates = dataArray.filter(o => o.sort_villa_sku >= start && o.sort_villa_sku <= stop)
                        start = stop + 1;
                        // console.log('-- dataUpdates --',dataUpdates);
                        var result = await api.updateProductSeq(JSON.stringify(dataUpdates));
                        console.log('-- result --',result);
                    }
                    alert('Save success.');
                }
              } catch (error) {
                alert(error)
              } finally {
                this.loading = false;
              }
          }
        },
        getImageUrl(item) {
          return `${imageUrl}/${item.image}`;
        },
        async exportClick() {
          try {
            this.loading = true;
            let cdate = (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10);
            const dataWS = XLSX.utils.json_to_sheet(this.dataExport)
            const wb = XLSX.utils.book_new()
            XLSX.utils.book_append_sheet(wb, dataWS)
            XLSX.writeFile(wb,`product_search_seq_${moment(this.cdate).format('YYYYMMDD')}.xlsx`)
          } catch (error) {
            alert(error)
          } finally {
            this.loading = false;
          }
        },
        async searchClick () {
            try {
                if (this.search == null || this.search == "") {
                  alert('Please Enter Search Text')
                  return;
                }
                this.loading = true;
                let filter = { searchText: this.search };
                let result = await api.searchProductSeq(filter);
                // console.log("--- data list ---", result.data);
                this.mDataArray = result.data;
            } catch (error) {
                alert(error)
            } finally {
                this.loading = false;
            }
        },
        onFileImportSelected(e) {
          try {
            this.loading = true;
            var files = e.target.files || e.dataTransfer.files;
            if (files.length == 0)
              return;
            this.previewFiles(files[0]);
          // } catch (error) {
          }
          finally {
            this.$refs.importFile.value = "";
            setTimeout(() => {
              this.loading = false;
            }, 2000);
          }
        },
        previewFiles(file) { // detect file event
            var reader = new FileReader();
            var vm = this;
            reader.onload = async function(e) {
                var data = new Uint8Array(e.target.result);
                var workbook = XLSX.read(data, { type: "array" });
                let sheetName = workbook.SheetNames[0];
                /* DO SOMETHING WITH workbook HERE */
                // console.log(workbook);
                let worksheet = workbook.Sheets[sheetName];
                // console.log(XLSX.utils.sheet_to_json(worksheet));
                let items = XLSX.utils.sheet_to_json(worksheet);
                // console.log('-- items --', JSON.stringify(items));
                // vm.sortedSeq(items);
                // console.log('-- items --', JSON.stringify(items));
                // let prcodeList = [];
                items.forEach(item => {
                  let mItem = vm.mDataArray.find(d => parseInt(d.cprcode) === parseInt(item.PrCode ?? 0));
                  if (mItem) {
                    mItem.sort_villa_sku = parseInt(item.Seq ?? 0);
                    // let index = vm.mDataArray.indexOf(mItem);
                    // vm.$set(vm.mDataArray, index, mItem);
                  }
                });
                vm.mDataArray = await vm.sortedArray(vm.mDataArray);
                // console.log('-- productList --', vm.mDataArray);
            };
            reader.readAsArrayBuffer(file);
        },
        sortedArray: function(data) {
          function compare(a, b) {
            let v1 = parseInt(a.sort_villa_sku ?? 0);
            let v2 = parseInt(b.sort_villa_sku ?? 0);
            if (v1 > v2)
              return -1;
            if (v1 < v2)
              return 1;

            // if (v1 < v2)
            //   return -1;
            // if (v1 > v2)
            //   return 1;
            return 0;
          }
          return data.sort(compare);
        },
    },

    computed: {
      userCode() {
        return this.$store.getters["userInfo"]["userCode"]?.toString() ?? "";
      },
    //   userGroup() {
    //     return this.$store.getters["userInfo"]["userGroup"]?.toString().toLowerCase().trim() ?? "";
    //   },
    //   userType() {
    //     return this.$store.getters["userInfo"]["userType"]?.toString() ?? "";
    //   },
    //   userBrcode() {
    //     return this.$store.getters["userInfo"]["brcode"]?.toString() ?? "";
    //   },
      dataExport() {
        if (this.mDataArray == null)
          return []
        else {
          let dataResult = [];
          this.mDataArray.forEach(item => {
            var data = {
              "Seq": item['sort_villa_sku'] ?? 0,
              "PrCode": item['cprcode'] ?? "", 
              "PrName": item['hema_name_en'] ?? "", 
              "CategoryLv1": item['online_category_l1_en'] ?? "", 
              "CategoryLv2": item['online_category_l2_en'] ?? "", 
              "CategoryLv3": item['online_category_l3_en'] ?? "",
              "Brand": item['hema_brand_en'] ?? "",
              "SizeDesc": item['hema_sizedesc'] ?? "",
            };
            dataResult.push(data);
          });
          return dataResult;
        };
      },
    }
  }
</script>

<style scoped>
.v-progress-circular {
  margin: 1rem;
}
.tableFixed {
   height: 500px;
   overflow: auto;
}
table {
  border-spacing: 0 0.5rem;
}
tbody tr:nth-of-type(odd) {
  background-color: rgba(0, 0, 0, .05);
}
</style>